import React, { useEffect } from 'react';
import { CheckBox } from 'react-native-elements';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Linking, Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign, Entypo, FontAwesome, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { CheckoutForm } from './checkoutForm';
import { Typography } from '../openFlow/style';
import { COLORS } from '../../constants/colorPallete';
import { showToaster } from '../../redux/actions/toast';
import getPlatformType from '../../helpers/getPlatform';
import WideButton from '../../commonComponents/wideButton';
import { CustomButton } from '../identifyAndFinance/stylesNew';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { STRIPEKEY, YOTI_URL } from '../../helpers/connectionHelpers';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { BoxShadow, IconButton, Line, Title2, TitleBlack, Wrapper } from './styles';
import { LineSaperator } from '../tds/style';
import { navigate } from '../../navigation/RootNavigation';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';

const IdVerificationNew = (props) => {
  const { isOpenflow } = props;
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [page, setPage] = React.useState(1);
  const [preQualData, setPreQualData] = React.useState([]);
  const globalState = useSelector((state) => state?.tenant);
  const [preRefData, setPreRefData] = React.useState(false);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');
  const [addOnSelected, setAddOnSelected] = React.useState(false);
  const [combinedPackage, setCombinedPackage] = React.useState({});
  const [selectedPackedge, setSelectedPackege] = React.useState('');
  const [referenceOnlyPackage, setReferenceOnlyPackage] = React.useState({});
  const [guarantorAddOnPackage, setGuarantorAddOnPackage] = React.useState({});
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const yotiRequestId = useSelector((state) => state.authReducer?.profile?.data?.user_id);
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const idVerification = useSelector(
    (state) => state.authReducer?.profile?.data?.appUser?.idverification
  );
  const hasUserSavedCookies = useSelector(
    (state) => state?.cookiesReducer?.userHasSavedCookiesPreferences
  );

  const { id_verification } = preQualData;

  let stripePromise = null;
  if (hasUserSavedCookies) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  if (authDataFromMobile) {
    stripePromise = loadStripe(STRIPEKEY);
  }

  const getButtonStatus = (id) => {
    if (id == 0 || id === 5 || id == 9) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (page == 1) {
      dispatch(tenantActions.getPreQualificationStatus());
      dispatch(tenantActions.getPackedge('/pre-qualification/id-verification-packages'));
    }
  }, []);

  useEffect(() => {
    if (globalState?.pack && globalState?.pack?.length) {
      const package1 = globalState?.pack.find((x) => x.product_code === 'tenant_id_verification');
      if (package1) {
        package1.name = 'ID Verification only';
      }

      const package2 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_digital_reference_and_id_verification'
      );
      if (package2) {
        package2.name = 'Pre-qualification Reference & ID Verification';
      }

      const package3 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_guarantor_digital_reference'
      );
      if (package3) {
        package3.name = '';
      }

      setGuarantorAddOnPackage(package3);
      setCombinedPackage(package2);
      setReferenceOnlyPackage(package1);
    }
  }, [globalState.pack]);

  const openLinkYoti = async (id) => {
    if (yotiRequestId && getButtonStatus(idVerification)) {
      let u = YOTI_URL + yotiRequestId;

      if (Platform.OS === 'web') {
        window.open(
          u,
          '_blank' // <- This is what makes it open in a new window.
        );
      } else {
        Linking.openURL(u).catch((err) => console.error('An error occurred', err));
      }
    } else {
      console.error('An error occurred', yotiRequestId, getButtonStatus());
    }
  };

  const downloadVerifiedDocuments = async () => {
    if (userData.yoti_verification_document) {
      window.open(userData.yoti_verification_document, '_blank');
    } else {
      alertActions.error('This user does not have verified Documents to download');
    }
  };

  useEffect(() => {
    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
    if (globalState?.preQualData?.preQualStatus.id_verification.payment_required) {
      setPage(1);
      setPreRefData(false);
    } else {
      setPreRefData(true);
    }
  }, [globalState]);
  const themeObj = getTokens('light').colors;
  const renderDocs = () => {
    return (
      <>
        {id_verification?.idverification == 9 ? (
          <>
            <Typography
              top={'6px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              It appears your id verification has failed, you may try once more. After that, you'll
              need to buy again.
            </Typography>
            <Typography
              top={'6px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              Check the reason for failure below.
            </Typography>
            <Title2 style={{ marginTop: 20 }} color={COLORS.LGREEN}>
              Verification failed reason:
            </Title2>
            <TitleBlack color={'#000000'} top={'10px'}>
              {userData?.yoti_reason}
            </TitleBlack>

            <Typography
              onPress={() => openLinkYoti()}
              align={'center'}
              top={'20px'}
              size={'16px'}
              bold={'400'}
              color={COLORS.LGREEN}
            >
              Try ID Verification Again
              <AntDesign
                style={{
                  justifyContent: 'flex-end',
                  marginLeft: 10,
                }}
                name="arrowright"
                size={20}
                color={COLORS.LGREEN}
              />
            </Typography>
          </>
        ) : id_verification?.idverification == 0 || id_verification?.idverification == 5 ? (
          <>
            <Typography
              top={'24px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              You can share your verified identity as many times as you like and you control who
              sees this information.
            </Typography>
            <Typography
              top={'24px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              You can use various documents to verify your ID but we recommend using a passport. If
              you have a British or Irish passport this will confirm your right to rent in England.
            </Typography>
            <Typography
              top={'24px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              Documents used for identity verification can be downloaded and saved in{' '}
              <b>My Documents</b>. You can then share them easily and securely by issuing a time
              limited share code to anyone you choose.
            </Typography>

            <View style={{ marginTop: 30 }}>
              <WideButton
                childText={'Get started'}
                gradiant
                isLGreen
                id={'rtryesgetstarted'}
                onPress={() => openLinkYoti()}
                ricon={<Entypo name="chevron-right" size={24} color="white" />}
              />
            </View>
            <Typography top={'24px'} align={'justify'} size={'13px'} bold={'300'} color={'#4f4f4f'}>
              When you press start you’ll be redirected to our Id Verification partner YOTI
            </Typography>
            {renderImagesSection()}
          </>
        ) : (
          <>
            {getDigitalVerificationStatus(
              id_verification?.idverification,
              id_verification?.yoti_reason
            )?.BtnText === 'Completed' ? (
              <>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  Congratulations! Your identity has been verified by Yoti, a UK Govt approved
                  Identity Service Provider.ntity as many times as you like and you control who sees
                  this information.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  Please go to My Documents to begin sharing documents as a verified user.
                </ODSText>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={10}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  You can also save this on your device by clicking the download button below.
                </ODSText>
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <ODSButton
                    rIcon={'TB-Download'}
                    restStyle={{ marginTop: 20, alignSelf: 'center', width: '100%' }}
                    type="primary"
                    disabled={false}
                    onPress={() => downloadVerifiedDocuments()}
                  >
                    Verification Doc
                  </ODSButton>
                </View>
                {renderImagesSection()}
              </>
            ) : null}
          </>
        )}
        {id_verification?.idverification == 1 ? (
          <>
            <Title2 style={{ marginTop: 20 }} color={COLORS.LGREEN}>
              Pending
            </Title2>

            <Typography
              id={''}
              top={'6px'}
              align={'justify'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              Yoti is still processing your data. This could take up to 24 hours. If you have any
              queries feel free to contact info@openbrix.co.uk
            </Typography>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const getTotalCost = () => {
    if (
      addOnSelected &&
      selectedPackedge &&
      selectedPackedge?.price_with_vat &&
      guarantorAddOnPackage &&
      guarantorAddOnPackage?.price_with_vat
    ) {
      const selectedPrice = parseFloat(selectedPackedge?.price_with_vat);
      const guarantorPrice = parseFloat(guarantorAddOnPackage?.price_with_vat);

      if (!isNaN(selectedPrice) && !isNaN(guarantorPrice)) {
        return (selectedPrice + guarantorPrice).toFixed(2);
      }
    } else if (!addOnSelected && selectedPackedge && selectedPackedge?.price_with_vat) {
      return selectedPackedge?.price_with_vat;
    } else if (addOnSelected && guarantorAddOnPackage && guarantorAddOnPackage?.price_with_vat) {
      return guarantorAddOnPackage?.price_with_vat;
    }
    return null;
  };

  const startPayment = () => {
    let payload = {
      ...selectedPackedge,
      addOnSelected,
      productId: selectedPackedge.product_id ? selectedPackedge.product_id : null,
    };
    dispatch(
      tenantActions.startPayment(payload, (data) => {
        if (data.status) {
          setClientSecret(data.data.client_secret);
          setPage(3);
        } else {
          dispatch(showToaster('error', 'Failed to start payment'));
        }
      })
    );
  };
  const renderImagesSection = () => {
    return (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: 10,
          }}
        >
          <Image
            source={require('./../../assets/images/yoti.svg')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,
              marginTop: 10,
            }}
          />
          <View style={{ height: 40, width: 2, backgroundColor: 'black' }} />
          <Image
            source={require('./../../assets/images/tlyfe.png')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,

              marginTop: 10,
            }}
          />
        </View>
        <Typography top={'24px'} size={'18px'} bold={'700'} color={'#4f4f4f'}>
          Digital Identity Checks
        </Typography>
        <Typography top={'10px'} size={'16px'} bold={'700'} color={'#4f4f4f'}>
          Smooth experience with YOTI and tlyfe
        </Typography>
        <Typography top={'14px'} size={'13px'} bold={'300'} color={'#4f4f4f'}>
          Yoti is the UK’s first certified Identity Services Provider (IDSP) under the Digital
          Identity and Attributes Trust Framework.
        </Typography>
        <Image
          source={require('./../../assets/images/gov.png')}
          resizeMode="contain"
          style={{
            height: 48,
            width: 100,
            alignSelf: 'center',
            marginTop: 10,
          }}
        />
      </>
    );
  };
  const renderPage1 = () => {
    return (
      <View style={{ flexDirection: 'column',width:'100%' }}>
        <BoxShadow bottom={'10px'} right={'19px'}>
          {getDigitalVerificationStatus(userData?.idverification, userData?.yoti_reason)
            ?.BtnText === 'Completed' ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Completed'}>
              Completed
            </ODSBadges>
          ) : null}

          {userData?.idverification == 1 ? (
            <ODSBadges restStyle={{ marginBottom: 10, alignSelf: 'flex-end' }} type={'Pending'}>
              Pending
            </ODSBadges>
          ) : null}

          <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
            ID Verification
          </ODSText>
          {/*<Typography top={"6px"} align={"left"} size={"14px"} bold={"400"} color={COLORS.BLACK}>
          Verify your identity! Share your details as many times as you want. You control who sees
          this information in a complete secure environment.
        </Typography>*/}
          {preRefData ? (
            renderDocs()
          ) : (
            <>
              <Typography
                top={'24px'}
                align={'justify'}
                size={'14px'}
                bold={'400'}
                color={COLORS.BLACK}
              >
                You can share your verified identity as many times as you like and you control who
                sees this information.
              </Typography>
              <Typography
                top={'24px'}
                align={'justify'}
                size={'14px'}
                bold={'400'}
                color={COLORS.BLACK}
              >
                You can use various documents to verify your ID but we recommend using a passport.
                If you have a British or Irish passport this will confirm your right to rent in
                England.
              </Typography>
              <Typography
                top={'24px'}
                align={'justify'}
                size={'14px'}
                bold={'400'}
                color={COLORS.BLACK}
              >
                Documents used for identity verification can be downloaded and saved in{' '}
                <b>My Documents</b>. You can then share them easily and securely by issuing a time
                limited share code to anyone you choose.
              </Typography>

              <View style={{ marginTop: 30 }}>
                <WideButton
                  childText={'Get started'}
                  gradiant
                  isLGreen
                  id={'rtryesgetstarted'}
                  onPress={() => openLinkYoti()}
                  ricon={<Entypo name="chevron-right" size={24} color="white" />}
                />
              </View>
              <Typography
                top={'24px'}
                align={'justify'}
                size={'13px'}
                bold={'300'}
                color={'#4f4f4f'}
              >
                When you press start you’ll be redirected to our Id Verification partner YOTI
              </Typography>
              {renderImagesSection()}
            </>
          )}
        </BoxShadow>
      </View>
    );
  };

  const renderPage2 = () => {
    return (
      <BoxShadow>
        <Typography
          align={'justify'}
          bottom={'10px'}
          size={'20px'}
          bold={'900'}
          color={COLORS.LGREEN}
        >
          Choose a package
        </Typography>

        <View style={{ flexDirection: 'column' }}>
          <View id="idverificationpack1" style={{ flexDirection: 'column' }}>
            <TouchableOpacity
              id={'idverificationpack1'}
              testID={'idverificationpack1'}
              onPress={() => {
                if (selectedPackedge?.product_id == combinedPackage?.product_id) {
                  setSelectedPackege('');
                } else {
                  selectedPackedge?.product_id != 300 && setAddOnSelected(false);
                  setSelectedPackege(combinedPackage);
                }
              }}
              style={{
                flexDirection: 'column',
                marginBottom: 10,
              }}
            >
              <BoxShadow
                id={'idverificationpack1'}
                style={{
                  borderWidth: selectedPackedge?.product_id == combinedPackage?.product_id ? 1 : 0,
                  borderColor: COLORS.LGREEN,
                  width: '100%',
                }}
                bottom={'10px'}
              >
                <View id={'sdsdsdsd'} style={{ alignSelf: 'flex-end' }}>
                  <Ionicons
                    name={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? 'md-radio-button-on'
                        : 'md-radio-button-off'
                    }
                    size={24}
                    color={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? COLORS.LGREEN
                        : COLORS.BLACK
                    }
                  />
                </View>
                <Typography align={'justify'} size={'16px'} bold={'700'} color={COLORS.LGREEN}>
                  {combinedPackage.name}
                </Typography>
                <Typography
                  align={'justify'}
                  top={'6px'}
                  size={'18px'}
                  bold={'400'}
                  color={COLORS.BLACK}
                >
                  £{combinedPackage?.price_with_vat} (inc. VAT)
                </Typography>
                <Typography
                  align={'justify'}
                  top={'10px'}
                  size={'14px'}
                  bold={'400'}
                  color={COLORS.BLACK}
                >
                  Prove you meet the referencing criteria by securing a Pre-qualification reference
                  and verify your ID to become a Rent Ready Tenant.
                </Typography>
              </BoxShadow>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            id={'idverificationpack2'}
            onPress={() => {
              if (selectedPackedge?.product_id == referenceOnlyPackage?.product_id) {
                setSelectedPackege('');
              } else {
                selectedPackedge?.product_id != 300 && setAddOnSelected(false);
                setSelectedPackege(referenceOnlyPackage);
              }
            }}
            style={{
              flexDirection: 'column',
              marginBottom: 10,
            }}
          >
            <BoxShadow
              style={{
                borderWidth:
                  selectedPackedge?.product_id == referenceOnlyPackage?.product_id ? 1 : 0,
                borderColor: COLORS.LGREEN,
                width: '100%',
              }}
              bottom={'10px'}
            >
              <View style={{ alignSelf: 'flex-end' }}>
                <Ionicons
                  name={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? 'md-radio-button-on'
                      : 'md-radio-button-off'
                  }
                  size={24}
                  color={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? COLORS.LGREEN
                      : COLORS.BLACK
                  }
                />
              </View>
              <Typography align={'justify'} size={'16px'} bold={'700'} color={COLORS.LGREEN}>
                {referenceOnlyPackage.name}
              </Typography>
              <Typography
                align={'justify'}
                top={'6px'}
                size={'18px'}
                bold={'400'}
                color={COLORS.BLACK}
              >
                £{referenceOnlyPackage?.price_with_vat} (inc. VAT)
              </Typography>
              <Typography
                align={'justify'}
                top={'10px'}
                size={'14px'}
                bold={'400'}
                color={COLORS.BLACK}
              >
                For tlyfe users who are already ID verified you can simply opt for the
                "Pre-qualification Reference only" route and use this, with your existing verified
                ID to become a Rent Ready Tenant
              </Typography>
            </BoxShadow>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '10px',
            maxWidth: 315,
          }}
        >
          <CheckBox
            checked={selectedPackedge?.product_id != 300 ? false : addOnSelected}
            onIconPress={() =>
              selectedPackedge?.product_id == 300 && setAddOnSelected(!addOnSelected)
            }
            checkedColor={COLORS.LGREEN}
            uncheckedColor={COLORS.GREY1}
            containerStyle={{ padding: 0 }}
          />
          <View style={{ width: '80%' }}>
            <Typography
              align={'justify'}
              top={'18px'}
              size={'16px'}
              bold={'700'}
              color={selectedPackedge?.product_id == 300 ? COLORS.LGREEN : COLORS.GREY1}
            >
              Add Guarantor Reference: £{guarantorAddOnPackage.price_with_vat} (inc. VAT)
            </Typography>
            <Typography
              align={'justify'}
              top={'10px'}
              size={'14px'}
              bottom={'18px'}
              bold={'400'}
              color={selectedPackedge?.product_id == 300 ? COLORS.BLACK : COLORS.GREY1}
            >
              If, having read the referencing criteria, you believe you need a guarantor, please
              select this option
            </Typography>
          </View>
        </View>
        <View style={{ maxWidth: 315 }}>
          <Typography
            top={'20px'}
            align={'justify'}
            size={'14px'}
            bold={'400'}
            color={COLORS.BLACK}
          >
            Note: if you use a UK or Irish passport to verify your ID, this will satisfy Right to
            Rent requirements
          </Typography>
        </View>

        <View
          style={{
            height: 1,
            backgroundColor: COLORS.GREY1,
            marginVertical: 20,
          }}
        />
        {selectedPackedge.product_code == 'tenant_digital_reference_and_id_verification' ? (
          <View style={{ maxWidth: 315 }}>
            <Typography
              align={'justify'}
              top={'10px'}
              size={'14px'}
              bold={'400'}
              color={COLORS.BLACK}
            >
              Before purchasing the Pre-Qualification Reference, you must read and agree to the
              terms and conditions below:
            </Typography>

            <View
              style={{
                flexDirection: 'row',
                width: 300,
                alignItems: 'center',
                marginTop: 12,
              }}
            >
              <CheckBox
                checked={agreeTerms}
                onIconPress={() => setAgreeTerms(!agreeTerms)}
                checkedColor={COLORS.LGREEN}
                uncheckedColor={COLORS.GREY1}
                containerStyle={{ padding: 0 }}
              />

              <Typography
                onPress={() =>
                  window.open(
                    'https://fccparagon.com/wp-content/uploads/2023/03/prequalificationreferencetermsandconditions.pdf',
                    '_blank'
                  )
                }
                align={'justify'}
                top={'10px'}
                size={'14px'}
                bold={'400'}
                underline={true}
                color={COLORS.LGREEN}
              >
                {' '}
                I agree to the terms and conditions of FCC Paragon Pre-qualification Reference
              </Typography>
            </View>
          </View>
        ) : null}
        <View
          style={{
            marginTop: 20,
            width: 200,
            alignSelf: 'flex-end',
          }}
        >
          <WideButton
            childText={'Checkout'}
            gradiant
            isLGreen
            id={'idvericheckout'}
            onPress={() => {
              if (selectedPackedge?.product_id) {
                if (
                  selectedPackedge?.product_code == 'tenant_digital_reference_and_id_verification'
                ) {
                  if (agreeTerms) {
                    startPayment();
                  } else {
                    dispatch(showToaster('error', 'Accept the terms'));
                  }
                } else {
                  startPayment();
                }
              } else {
                dispatch(showToaster('error', 'Please select one package to proceed!'));
              }
            }}
          />
        </View>
      </BoxShadow>
    );
  };

  const renderPage3 = () => {
    return (
      <BoxShadow>
        <Typography
          align={'justify'}
          bottom={'20px'}
          size={'20px'}
          bold={'900'}
          color={COLORS.LGREEN}
        >
          Checkout
        </Typography>

        <View
          style={{
            flexDirection: 'column',
          }}
        >
          <Typography
            align={'justify'}
            top={'18px'}
            size={'16px'}
            bold={'700'}
            color={COLORS.LGREEN}
          >
            {selectedPackedge?.name} £{selectedPackedge.price_with_vat}
          </Typography>

          {addOnSelected ? (
            <Typography
              align={'justify'}
              top={'18px'}
              size={'16px'}
              bold={'700'}
              color={COLORS.LGREEN}
            >
              Guarantor Reference £{guarantorAddOnPackage.price_with_vat}
            </Typography>
          ) : null}

          <Typography
            id={'idverificationtotalcost'}
            align={'justify'}
            top={'6px'}
            size={'18px'}
            bold={'400'}
            bottom={'20px'}
            color={COLORS.BLACK}
          >
            Total cost: £{getTotalCost()}
          </Typography>
          {authDataFromMobile ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
          {hasUserSavedCookies ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
        </View>
      </BoxShadow>
    );
  };

  const renderPage6 = () => {
    return (
      <BoxShadow>
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <Typography align={'center'} top={'20px'} size={'20px'} bold={'900'} color={COLORS.SAFFRON}>
          Payment Failed!
        </Typography>
        <View
          style={{
            marginTop: 23,
            width: 42,
            height: 1.5,
            alignSelf: 'center',
            backgroundColor: COLORS.LGREEN,
          }}
        />
        <Typography
          onPress={() => {
            setPage(2);
          }}
          align={'center'}
          top={'20px'}
          size={'16px'}
          bold={'400'}
          color={COLORS.LGREEN}
        >
          Back
          <AntDesign
            style={{
              justifyContent: 'flex-end',
              marginLeft: 10,
            }}
            name="arrowright"
            size={20}
            color={COLORS.LGREEN}
          />
        </Typography>
      </BoxShadow>
    );
  };

  const renderPage4 = () => {
    return (
      <BoxShadow>
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <Typography
          id={'paymentsuccess_idverification'}
          align={'center'}
          top={'20px'}
          size={'20px'}
          bold={'900'}
          color={COLORS.BLACK}
        >
          Payment successful!
        </Typography>
        <View
          style={{
            marginTop: 23,
            width: 42,
            height: 1.5,
            alignSelf: 'center',
            backgroundColor: COLORS.LGREEN,
          }}
        />
        <Typography top={'24px'} align={'justify'} size={'14px'} bold={'400'} color={COLORS.BLACK}>
          IMPORTANT: If you have a British or Irish passport, we
          <Typography size={'14px'} color={COLORS.BLACK} bold={'800'}>
            {' '}
            strongly{' '}
          </Typography>
          recommend uploading this, as your right to rent will be automatically confirmed.
        </Typography>
        <Typography
          onPress={() => openLinkYoti()}
          align={'center'}
          top={'20px'}
          size={'16px'}
          bold={'400'}
          color={COLORS.LGREEN}
          id={'start_idverification'}
        >
          Start ID Verification
          <AntDesign
            style={{
              justifyContent: 'flex-end',
              marginLeft: 10,
            }}
            name="arrowright"
            size={20}
            color={COLORS.LGREEN}
          />
        </Typography>
        <Image
          source={require('./../../assets/images/yoti.svg')}
          resizeMode="contain"
          style={{
            height: 38,
            width: 86,
            alignSelf: 'center',
            marginTop: 35,
          }}
        />
        <Typography align={'center'} top={'13px'} size={'13px'} bold={'300'} color={COLORS.BLACK}>
          When you press start you’ll be redirected to our Id Verification partner YOTI
        </Typography>

        <Typography top={'24px'} align={'justify'} size={'13px'} bold={'300'} color={COLORS.BLACK}>
          Confirmation of ID Verification may take up to 24 hours. If you have not received
          confirmation of verification within 24 hour please check the{' '}
          <Typography
            onPress={() => {
              window.open('https://www.yoti.com/terms/', '_blank');
            }}
            color={COLORS.GREEN}
            size={'13px'}
            bold={'300'}
          >
            YOTI terms and conditions
          </Typography>{' '}
          for next steps
        </Typography>
      </BoxShadow>
    );
  };

  return (
    <>
      <View
        style={
          layoutType == 'phone'
            ? { width: '100%' }
            : { flex: 1, flexDirection: 'column', marginLeft: 5 }
        }
      >
        {page == 1
          ? renderPage1()
          : page == 2
          ? renderPage2()
          : page == 3
          ? renderPage3()
          : page == 4
          ? renderPage4()
          : page == 6
          ? renderPage6()
          : null}
      </View>
    </>
  );
};

export default IdVerificationNew;
