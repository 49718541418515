import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View, Image, Linking } from 'react-native';
import FilePicker from '../../commonComponents/filePicker/filepicker.web';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSdocumentCard from '../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import RequestDocs from '../openFlow/molecules/requestDocs';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.web';
import { getFullReferencingVerificationStatus } from '../openFlow/helpers';
import getPlatformType from '../../helpers/getPlatform';
import { COLORS } from '../../constants/colorPallete';
import { LineSaperator,BoxShadow } from '../tds/style';
import { MainSection, Typography } from '../openFlow/style';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';

const layoutType = getPlatformType();

export const AccordianCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const { title, status, comments, lastUpdate } = props;
  const getStatus = (status) => {
    switch (status) {
      case 'COMPLETE':
        return {
          uri: require('./completed.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'NOT STARTED':
        return {
          uri: require('./notStarted.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'N/A':
        return {
          uri: require('./notStarted.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      case 'PENDING':
        return {
          uri: require('./inProgress.png'),
          styles: { width: 20, height: 20 },
        };
        break;
      default:
        return {
          uri: require('./inProgress.png'),
          styles: { width: 20, height: 20 },
        };
        break;
    }
  };

  return (
    <React.Fragment>
      <LineSaperator color={'#D9D9D9'} />
      <View style={{ flexDirection: 'row', height: 70 }}>
        <View
          style={{
            flex: 0.3,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Image
            source={getStatus(status).uri}
            resizeMode="contain"
            style={getStatus(status.toProperCase()).styles}
          />
          <ODSText marginTop={6} type={'xs'}>
            {status.toProperCase()}
          </ODSText>
        </View>
        <View style={{ flex: 0.5, justifyContent: 'center' }}>
          <ODSText marginTop={6} marginLeft={6} type={'md'}>
            {title}
          </ODSText>
        </View>
        <TouchableOpacity
          onPress={() => {
            setIsOpen(!isOpen);
          }}
          style={{ flex: 0.2, justifyContent: 'center', alignItems: 'center' }}
        >
          <Entypo name={isOpen ? 'chevron-up' : 'chevron-down'} size={23} color={COLORS.LGREEN} />
        </TouchableOpacity>
      </View>
      {isOpen ? (
        <View style={{ marginHorizontal: 25 }}>
          <ODSText marginTop={16} type={'button'}>
            Status
          </ODSText>
          <ODSText marginTop={6} type={'sm'}>
            {status.toProperCase()}
          </ODSText>

          <ODSText marginTop={16} type={'button'}>
            Comments
          </ODSText>
          <ODSText marginTop={6} type={'sm'}>
            {comments}
          </ODSText>

          <ODSText marginTop={16} type={'button'}>
            Last update:
          </ODSText>
          <ODSText marginBottom={10} marginTop={6} type={'sm'}>
            {lastUpdate}
          </ODSText>
        </View>
      ) : null}
    </React.Fragment>
  );
};
const UploadRef = (props) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [showButton, setButton] = useState(true);
  const [currentTenant, setCurrentTenant] = React.useState({});
  const [referencingDocuments, setReferencingDocuments] = React.useState([]);

  useEffect(() => {
    if (props.currentTenant) {
      setCurrentTenant(props.currentTenant);
      let documents = props.currentTenant?.open_flow_verifications;
      if (documents && documents.length > 0) {
        documents = documents.filter((document) => {
          return document.type_of_document == 'referencing';
        });
        setReferencingDocuments(documents);
      } else {
        setReferencingDocuments([]);
      }
    }
  }, [props.currentTenant]);

  const modifyDate = (date) => {
    return date.substr(0, 10).split('-').join('/');
  };

  const openReferencingLink = async (url, id) => {
    if (url) {
      window.open(url, '_blank');
    }
  };
  const StatusButton = (type) => {
    if (type === "COMPLETED") {
        return 'Completed';
    } else if (type=="NOT STARTED") {
        return 'Not Started';
    } else if (type=="IN PROGRESS") {
        return 'Pending';
    } else if (type=="ACTION REQUIRED") {
        return 'Action Required';
    } else if (type == 6) {
        return 'In Progress';
    } else {
        return 'Pending';
    }
};

  const renderDetailsCard = () => {
    return (
      <View style={{ paddingHorizontal: layoutType == 'phone' ? 10 : 20 }}>
       
       <ODSBadges restStyle={{ alignSelf: 'flex-end' ,marginTop:12}} type={StatusButton(props?.status)}>
                                   {StatusButton(props?.status)}
         </ODSBadges>
        <ODSText marginTop={10} type={'h5'}>
          Full Reference
        </ODSText>
        <ODSText marginTop={10} type={'sm'}>
        Please find below information related to you tenancy reference.
        </ODSText>

        <View>
        <ODSText marginTop={24} type={'md-bold'}>
              Reference Handler:
            </ODSText>
            <ODSText marginTop={6} type={'md'}>
            {props?.latestUpdate?.handlerName ? props?.latestUpdate?.handlerName : null}
            </ODSText>
        </View>
        <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:24}}>
        <View>
        <ODSText marginTop={12} type={'md-bold'}>
              Tel:
            </ODSText>
            <ODSText marginTop={6} type={'md'}>
            {props?.latestUpdate?.handlerContactNumber ? props?.latestUpdate?.handlerContactNumber : null}
            </ODSText>
        </View>
        <View>
        <ODSText marginTop={12} type={'md-bold'}>
              Ref:
            </ODSText>
            <ODSText marginTop={6} type={'md'}>
            {props?.ref_id}
            </ODSText>
        </View>
        </View>

        
        <AccordianCard
          title={'Employment'}
          status={
            props?.latestUpdate?.creditSearch?.status
              ? props?.latestUpdate?.creditSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.creditSearch?.comments
              ? props?.latestUpdate?.creditSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.currentEmployment?.last_updated
              ? moment(props?.latestUpdate?.currentEmployment?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Credit Search'}
          status={
            props?.latestUpdate?.creditSearch?.status
              ? props?.latestUpdate?.creditSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.creditSearch?.comments
              ? props?.latestUpdate?.creditSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.creditSearch?.last_updated
              ? moment(props?.latestUpdate?.creditSearch?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Living Status'}
          status={
            props?.latestUpdate?.currentLiving?.status
              ? props?.latestUpdate?.currentLiving?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.currentLiving?.comments
              ? props?.latestUpdate?.currentLiving?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.currentLiving?.last_updated
              ? moment(props?.latestUpdate?.currentLiving?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Tenant Search'}
          status={
            props?.latestUpdate?.tenantSearch?.status
              ? props?.latestUpdate?.tenantSearch?.status
              : 'Not Started'
          }
          comments={
            props?.latestUpdate?.tenantSearch?.comments
              ? props?.latestUpdate?.tenantSearch?.comments
              : 'N/A'
          }
          lastUpdate={
            props?.latestUpdate?.tenantSearch?.last_updated
              ? moment(props?.latestUpdate?.tenantSearch?.last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
      </View>
    );
  };
  const openUrl = (url) => {
    if (url) {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          if (url) {
            let ext = url.split('.').reverse()[0];
            if (ext === 'pdf') {
              window.open(
                // 'https://docs.google.com/viewerng/viewer?url='+
                url,
                '_blank'
              );
            } else {
              window.open(url, '_blank');
            }
          }
        } else {
        }
      });
    }
  };
  const renderUploadDocs = () => {
    return (
      <View style={{ paddingHorizontal: layoutType == 'phone' ? 10 : 20 }}>
        <ODSText marginTop={10} type={'h5'}>
          Upload docs
        </ODSText>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 'none' }}>
            <ODSText marginTop={10} type={'button'}>
              Tenant Reference Company
            </ODSText>

            <ODSText marginTop={10} type={'sm'}>
              In this section you can submit document directly to the{' '}
              <ODSText color="#FC4850" type={'sm'}>
                tenant reference company ONLY.
              </ODSText>
            </ODSText>

            <ODSText marginTop={10} type={'button'}>
              Please note:{' '}
              <ODSText type={'sm'}>
                If you have been asked by the Agent to submit documentation, DO NOT use this
                section, please use the section Marked - "Letting Agent"
              </ODSText>
            </ODSText>
          </View>
          <View style={{ flex: 'none' }}>
            <View
              style={{
                marginTop: 10,
                flexDirection: 'column',
                marginBottom: 20,
              }}
            >
              {props?.requestedDocs && props?.requestedDocs.length ? (
                props?.requestedDocs?.map((e, index) => (
                  <RequestDocs
                    item={e}
                    index={index}
                    width={'100%'}
                    fcc={true}
                    fcc_applicant_id={props?.ref_id}
                    tenant={currentTenant}
                    branch={props?.branch}
                    docLength={props?.requestedDocs?.length}
                    refreshApi={() => {
                      props?.onRefresh();
                    }}
                  />
                ))
              ) : (
                <ODSText type={'sm'}>
                  Upload boxes will appear here once fcc requests a document
                </ODSText>
              )}
            </View>
          </View>
        </View>
        
      </View>
    );
  };

  return (
    <MainSection
      style={{
        flexDirection: layoutType == 'phone' ? 'column' : 'row',
        justifyContent: 'space-between',
        marginTop:0
      }}
    >
      <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
        <BoxShadow style={{ padding: 10 }}>{renderDetailsCard()}</BoxShadow>
      </View>
      <View style={layoutType == 'phone' ? { flex: 1, marginTop: 26 } : { flex: 0.48 }}>
        <BoxShadow style={{ padding: 10 }}>{renderUploadDocs()}</BoxShadow>
      </View>
    </MainSection>
  );
};
export default UploadRef;
