import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Switch, ScrollView } from 'react-native';
import { ODSText } from '../../atoms/ODSText';
import { useTheme } from '@emotion/react';
import ODSButton from '../../atoms/ODSButton';
import { ODSInput } from '../../atoms/ODSInput';
import Regex from '../../../utils/regex';
import getScreenType from '../../../utils/getScreenType';

const SCSteps = ({ DocumentData, onShareSave, onModalClose }) => {
  const { colors } = useTheme();
  const [shareStep, setShareStep] = React.useState(0);
  const [showError, setShowError] = useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [agentEmail, setAgentEmail] = React.useState('');
  const layout = getScreenType();

  const [points, setPoints] = useState([
    {
      name: 'Tenant Info',
      isShared: false,
    },
    {
      name: 'Tenant Affordability',
      isShared: false,
    },
    {
      name: 'Guarantor Affordability',
      isShared: false,
    },
    {
      name: 'Rent Ready Reference',
      isShared: false,
    },
    {
      name: 'Rent Record',
      isShared: false,
    },
    {
      name: 'Right to Rent',
      isShared: false,
    },
    {
      name: 'ID Verification',
      isShared: false,
    },
  ]);
  useEffect(() => {
    if (DocumentData) {
      setDocuments(DocumentData);
    }
  }, [DocumentData]);

  const handleShare = (point: any) => {
    setPoints(
      points.map((point1) => {
        if (point.name == point1.name) {
          point1.isShared = !point1.isShared;
        }
        return point1;
      }),
    );
  };
  const [selectAll, setSelectAll] = useState(false);
  // Check if all items are selected
  const isAllSelected = points.every((point) => point.isShared);
  const [selectAllDocs, setSelectAllDocs] = useState(false);
  // Check if all items are selected

  const isAllSelectedDocs = documents?.every((point) => point.isShared);

  const toggleSelectAll = () => {
    setSelectAll(!isAllSelected); // Toggle the "Select All" checkbox

    const updatedPoints = points.map((point) => ({
      ...point,
      isShared: !isAllSelected, // Toggle the selection state of all items
    }));
    setPoints(updatedPoints);
  };
  useEffect(() => {
    setSelectAll(isAllSelected);
  }, [isAllSelected]);
  const renderStep0 = () => {
    return (
      <View style={{ marginTop: 0 }}>
        <ODSText type="md" color={colors.text.secondary} marginBottom={16}>
          Please select the items you want to share
        </ODSText>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <View
            style={{
              flexDirection: 'row',
              height: 56,
              alignItems: 'center',
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Switch onValueChange={toggleSelectAll} value={selectAll} />
            </View>
            <ODSText marginLeft={8} type="md" color={colors.text.primary}>
              Select All
            </ODSText>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            marginVertical: 8,
            height: 1,
            backgroundColor: colors.other['border-neutral'],
          }}
        />
        <View style={{ width: 358 }}>
          {points.map((item, index) => (
            <View
              key={index}
              style={{
                flexDirection: 'row',
                height: 56,
                alignItems: 'center',
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Switch
                  onValueChange={() => handleShare(item)}
                  value={item.isShared ? true : false}
                />
              </View>
              <ODSText marginLeft={8} type="md" color={colors.text.primary}>
                {item?.name}
              </ODSText>
            </View>
          ))}
        </View>
        <ODSButton
          restStyle={{ alignSelf: 'center', width: '100%', marginTop: 24 }}
          type="primary"
          onPress={() => setShareStep(1)}
          themeColor={colors.main.purple}
          disabled={false}>
          Next
        </ODSButton>
        <ODSButton
          restStyle={{ alignSelf: 'center', width: '100%', marginTop: 12 }}
          type="secondary"
          onPress={() => onModalClose()}
          themeColor={colors.main.purple}
          disabled={false}>
          Close
        </ODSButton>
      </View>
    );
  };
  const toggleSelectAllDocs = () => {
    setSelectAllDocs(!isAllSelectedDocs); // Toggle the "Select All" checkbox

    const updatedPoints = documents.map((point) => ({
      ...point,
      isShared: !isAllSelectedDocs, // Toggle the selection state of all items
    }));
    setDocuments(updatedPoints);
  };
  useEffect(() => {
    setSelectAllDocs(isAllSelectedDocs);
  }, [isAllSelectedDocs]);
  const renderStep1 = () => {
    const isDocumentSelected = documents.some((item: any) => item.isShared === true);
    const isPointsSelected = points.some((item: any) => item.isShared === true);

    return (
      <View style={{ margin: 0 }}>
        <ODSText type="md" color={colors.text.secondary} marginBottom={16}>
          Please select the documents you want to share
        </ODSText>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <View
            style={{
              flexDirection: 'row',
              height: 56,
              alignItems: 'center',
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Switch onValueChange={toggleSelectAllDocs} value={selectAllDocs} />
            </View>

            <ODSText marginLeft={8} type="md" color={colors.text.primary}>
              Select All
            </ODSText>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            marginVertical: 8,
            height: 1,
            backgroundColor: colors.other['border-neutral'],
          }}
        />
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={{
            width: 358,
            maxHeight: 400,
          }}>
          {documents.length
            ? documents.map((document) => {
              return renderItem1(document);
            })
            : null}
        </ScrollView>
        {showError ? (
          isDocumentSelected || isPointsSelected ? null : (
            <ODSText
              marginVertical={10}
              alignSelf={'center'}
              type="sm"
              color={colors.feedback.error[500]}>
              Please select atleast one document or item to proceed!
            </ODSText>
          )
        ) : null}
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
            width: '100%',
          }}>
          <ODSButton
            restStyle={{ alignSelf: 'center', width: '100%', marginTop: 12 }}
            type="secondary"
            onPress={() => setShareStep(0)}
            themeColor={colors.main.purple}
            disabled={false}>
            Back
          </ODSButton>
          <ODSButton
            restStyle={{ alignSelf: 'center', width: '100%', marginTop: 12 }}
            type="primary"
            onPress={() => {
              if (isDocumentSelected || isPointsSelected) {
                setShowError(false);
                setShareStep(2);
              } else {
                setShowError(true);
              }
            }}
            themeColor={colors.main.purple}
            disabled={false}>
            Next
          </ODSButton>
        </View>
      </View>
    );
  };
  const handleShare1 = (document: any) => {
    setDocuments(
      documents.map((document1) => {
        if (document.document_id == document1.document_id) {
          document1.isShared = !document1.isShared;
        }
        return document1;
      }),
    );
  };
  const renderItem1 = (document) => {
    return (
      <View key={document.document_id} style={{}}>
        <View
          style={{
            flexDirection: 'row',
            height: 56,
            alignItems: 'center',
          }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Switch
              onValueChange={() => handleShare1(document)}
              value={document.isShared ? true : false}
            />
          </View>
          <TouchableOpacity onPress={() => handleShare1(document)}>
            <ODSText marginLeft={8} type="md" color={colors.text.primary}>
              {document.filename}
            </ODSText>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const onSave = () => {
    const documentIds = [];
    documents.map((document) => {
      if (document.isShared) {
        documentIds.push(document.document_id);
      }
    });
    onShareSave({
      email: agentEmail,
      points,
      documents: documentIds,
    });
    onModalClose();
    setShareStep(0);
    setAgentEmail('');
  };
  const renderStep2 = () => {
    return (
      <View style={{ margin: 0 }}>
        <ODSInput
          id="agentEmail"
          onChange={(value) => setAgentEmail(value)}
          placeholder={'Enter receiver’s email'}
          label="Receiver’s email"
          value={agentEmail}
        />
        <ODSText type="md" color={colors.text.primary} marginTop={16}>
          We will send an email containing a link to the secure portal where your shared documents
          can be viewed for the next
          <ODSText type="md-bold" color={colors.text.primary}>
            {' '}
            48 hours
          </ODSText>
        </ODSText>
        <ODSText type="md" color={colors.text.primary} marginTop={16}>
          Please unshare any documents you do not wish to be viewed.
        </ODSText>
        <ODSText type="md" color={colors.text.primary} marginTop={16}>
          Your documents can be shared as many times as you like.
        </ODSText>

        <View
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
            width: '100%',
          }}>
          <ODSButton
            restStyle={{ alignSelf: 'center', width: '100%', marginTop: 12 }}
            type="secondary"
            onPress={() => setShareStep(1)}
            themeColor={colors.main.purple}
            disabled={false}>
            Back
          </ODSButton>
          <ODSButton
            restStyle={{ alignSelf: 'center', width: '100%', marginTop: 12 }}
            type="primary"
            onPress={() => {
              setShowError(false);
              onSave();
            }}
            themeColor={colors.main.purple}
            disabled={Regex.validateEmail(agentEmail) ? false : true}>
            Send Email
          </ODSButton>
        </View>
      </View>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <ODSText type="h5" color={colors.text.primary} textAlign="center">
        New Share Code
      </ODSText>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: 24,
        }}>
        {[1, 2, 3].map((e) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <View
              style={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 30,
                borderWidth: 2,
                borderColor:
                  e == shareStep + 1
                    ? colors.main.purple[400]
                    : shareStep + 1 > e
                      ? 'transparent'
                      : colors.disabled.element,
                backgroundColor:
                  e == shareStep + 1
                    ? colors.main.purple[100]
                    : shareStep + 1 > e
                      ? colors.main.purple[500]
                      : 'white',
              }}>
              <ODSText
                type="md-bold"
                color={
                  e == shareStep + 1
                    ? colors.main.purple[400]
                    : shareStep + 1 > e
                      ? 'white'
                      : colors.disabled.element
                }>
                {e}
              </ODSText>
            </View>
            {e != 3 && (
              <View
                style={{
                  width: layout == 'phone' ? 80 : 120,
                  height: 1,
                  backgroundColor:
                    shareStep + 1 >= e ? colors.main.purple[400] : colors.disabled.element,
                  marginHorizontal: 10,
                  alignSelf: 'center',
                }}
              />
            )}
          </View>
        ))}
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        style={{ marginTop: 24 }}>
        {shareStep == 0 && renderStep0()}
        {shareStep == 1 && renderStep1()}
        {shareStep == 2 && renderStep2()}
      </ScrollView>
    </View>
  );
};
export default SCSteps;
