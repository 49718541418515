import { tenantConstants } from './tenant.constants';
import { TenantService } from './tenant.service';
import { showToaster } from '../../../redux/actions/toast';
import { types } from './../../../redux/actionTypes';
import { fetchDoc, getProfile } from '../../../redux/actions/auth';

export const tenantActions = {
  viewOpenFlowDepositCertificate,
  getPreQualificationStatus,
  getRentalOffers,
  addRentalOffer,
  getOnboardedTeanants,
  getMoveIn,
  addMoveIn,
  arrangeCheckIn,
  getTenancyInfo,
  getNHA,
  getAST,
  addTenancyInfo,
  getFlowInfo,
  cleanGetFlowInfo,
  saveSlectedProperty,
  startPayment,
  getTeanants,
  getDocuments,
  saveTeanantDetails,
  toggleComplete,
  getHoldingDeposit,
  requestHoldingDeposit,
  confirmDeposit,
  buyCer,
  delCer,
  getIdentityData,
  saveIdentityData,
  getTdsData,
  saveMoveinData,
  toggleSeen,
  confirmGaurantor,
  uploadManualAST,
  acceptMoveinApi,
  uploadReferenceDocs,
  uploadRightToRentDocs,
  uploadTenantRef,
  deleteDoc,
  deleteRefDocument,
  paywithordo,
  postRtrCode,
  searchAddress,
  requestLoan,
  zopaFollowup,
  getPackedge,
  saveAgentEmail,
  postCodesStatus,
  getCodesStatus,
  getPreRefUri,
  getFccRequestedReferenceDocs,
  addGuarantorToReference,
  getInsuranceClicked,
  getTilesData,
  getBroadBandRecommendations,
  setPostCodeAction,
  getBillingBetterPackages,
  getBillingBetterIframeURL,
  getInsuranceDocs,
  getTenantInfo,
  saveTenantInfo,
  payWithVoucher,
  getRewardsSubsStatus,
  getRewardsUrl,
  getOpenflowData,
  getSignAstStatus,
  toggleSeenAll,
  getFccPropertiesData,
  getReferenceFromId,
  uploadRefDocsNoAgent
};
function getInsuranceClicked(navigation) {
  console.log('navigation', navigation);
  return (dispatch) => {
    dispatch(request());
    TenantService.getInsuranceClicked().then((data) => {
      if (data?.status) {
        dispatch(success());
      } else {
        if (data.message) {
          dispatch(showToaster('error', data.message));
        }
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_INSURANCE_CLICKER };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_INSURANCE_CLICKER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_INSURANCE_CLICKER_FAILURE,
      error,
    };
  }
}

function setPostCodeAction(payload) {
  return (dispatch) => {
    dispatch(success(payload));
  };
  function success(payload) {
    return { type: tenantConstants.SET_POSTCODE_ACTION, data: payload };
  }
}

function getTilesData(cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTiles()
      .then((data) => {
        dispatch(success());
        if (data.status) {
          if (cb) {
            cb(data.data);
          }
        } else {
          if (data.message) {
            dispatch(showToaster('error', data.message));
          }
        }
      })
      .catch((e) => dispatch(failure()));
  };

  function request() {
    return { type: tenantConstants.GET_INSURANCE_CLICKER };
  }
  function success(data) {
    return {
      type: tenantConstants.GET_INSURANCE_CLICKER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_INSURANCE_CLICKER_FAILURE,
      error,
    };
  }
}

function getBroadBandRecommendations(payload, cb) {
  return (dispatch) => {
    TenantService.getBroadband(payload).then((data) => {
      if (data?.status) {
        cb(data.data);
      } else {
        if (data?.message) {
          dispatch(showToaster('error', data.message));
        }
      }
    });
  };
}

function getBillingBetterPackages(payload, cb) {
  return (dispatch) => {
    TenantService.getPackages(payload)
      .then((data) => {
        if (data.status) {
          // dispatch(success());
          cb(data.data);
        } else {
          cb(data);
          if (data.message) {
            dispatch(showToaster('error', data.message));
          }
        }
      })
      .catch((e) => cb(null));
  };
}
function getBillingBetterIframeURL(payload, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getIFrameURL(payload)
      .then((data) => {
        dispatch(success());
        if (data.status) {
          cb(data.data);
        } else {
          cb(data);
          if (data.message) {
            dispatch(showToaster('error', data.message));
          }
        }
      })
      .catch((e) => {
        dispatch(failure());
        cb(null);
      });
  };

  function request() {
    return { type: tenantConstants.GET_BILLING_BETTER_IFRAME_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_BILLING_BETTER_IFRAME_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_BILLING_BETTER_IFRAME_FAILURE,
      error,
    };
  }
}

function addGuarantorToReference(payload, tenant_prequal_id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addGuarantorToReference(payload, tenant_prequal_id).then((data) => {
      if (data.status) {
        dispatch(success());
        if (cb) {
          cb(data);
        }
      } else {
        if (data.message) {
          dispatch(showToaster('error', data.message));
        }
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.ADD_GUARANTOR_TO_REFERENCE_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.ADD_GUARANTOR_TO_REFERENCE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.ADD_GUARANTOR_TO_REFERENCE_FAILURE,
      error,
    };
  }
}

function getPreRefUri(cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getPreRefUri().then((data) => {
      console.log('getCodesStatus', data);
      if (data.status) {
        dispatch(success());
        if (cb) {
          cb(data);
        }
      } else {
        if (data.message) {
          dispatch(showToaster('error', data.message));
        }

        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_CODES };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_CODES_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_CODES_FAILURE,
      error,
    };
  }
}

function getCodesStatus() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getCodesStatus().then((data) => {
      console.log('getCodesStatus', data);
      if (data.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_CODES };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_CODES_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_CODES_FAILURE,
      error,
    };
  }
}

function getRewardsUrl(cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getRewardsUrl().then((data) => {
      dispatch(success());
      console.log("getRewardsSubsStatus",data)
      if(data?.status){
        if(cb){
          cb(data?.data)
        }
      }else if(data?.message){
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: data.message,
        });
      }
      
    }).catch((e) => {
      dispatch(failure());
    });
  };
  function request() {
    return { type: tenantConstants.GET_REWARDS_SUBS_STATUS };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_REWARDS_SUBS_STATUS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_REWARDS_SUBS_STATUS_FAILURE,
      error,
    };
  }
}

function getRewardsSubsStatus(cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getRewardsSubsStatus().then((data) => {
      if(cb){
        cb(data)
      }
      if (data.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
      }
    }).catch((e) => {
      dispatch(failure(e));
    });
  };

  function request() {
    return { type: tenantConstants.GET_REWARDS_SUBS_STATUS };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_REWARDS_SUBS_STATUS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_REWARDS_SUBS_STATUS_FAILURE,
      error,
    };
  }
}
function postCodesStatus(pay, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.postCodesStatus(pay).then((data) => {
      console.log('getCodesStatus', data);
      if (data.status) {
        dispatch(success());
        if (cb) {
          cb(data);
        }
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.POST_CODES };
  }

  function success(data) {
    return {
      type: tenantConstants.POST_CODES_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.POST_CODES_FAILURE,
      error,
    };
  }
}

function saveAgentEmail(pay, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveAgentEmail(pay).then((data) => {
      if (data.status) {
        dispatch(success());
        if (cb) {
          cb(data);
        }
      } else {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: data.message,
        });
        dispatch(failure(data.message));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_PACK };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_PACK_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_PACK_FAILURE,
      error,
    };
  }
}

function startPayment(pay, cb,isPaymentTypeRewards) {
  return (dispatch) => {
    dispatch(request());
    TenantService.startPayment(pay,isPaymentTypeRewards).then((data) => {
      if (data.status) {
        dispatch(success());
      } else {
        dispatch(failure(data));
      }
      cb(data);
    });
  };

  function request() {
    return { type: tenantConstants.MAKE_PAYMENTS };
  }

  function success() {
    return { type: tenantConstants.MAKE_PAYMENTSSUCCESS };
  }

  function failure(error) {
    return {
      type: tenantConstants.MAKE_PAYMENTSFAILURE,
      error,
    };
  }
}

function getPackedge(url, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getPackedge(url).then((data) => {
      console.log('pack dara', data);
      if (data.status) {
        if (cb) {
          cb(data);
        } else {
          dispatch(success(data?.data));
        }
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_PACK };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_PACK_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_PACK_FAILURE,
      error,
    };
  }
}

function getPreQualificationStatus() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getPreQualificationStatus().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_Prequalification_failure };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_Prequalification_success,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_Prequalification_failure,
      error,
    };
  }
}

function requestLoan(payload, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestLoan(payload).then((data) => {
      if (data.status) {
        dispatch(success(data));
        cb(data);
      } else {
        dispatch(failure(data));
        cb(data);
      }
    });
  };

  function request() {
    return { type: tenantConstants.REQUEST_LOAN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.REQUEST_LOAN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.REQUEST_LOAN_SUCCESS,
      error,
    };
  }
}

function zopaFollowup(id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.zopaFollowup(id).then((data) => {
      if (data.status) {
        cb(data);
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Something went wrong',
        });
      }
    });
  };

  function request() {
    return { type: tenantConstants.FOLLOWUP_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.FOLLOWUP_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.FOLLOWUP_ERROR,
      error,
    };
  }
}

function searchAddress(postCode) {
  return (dispatch) => {
    dispatch(request());
    TenantService.searchAddress(postCode).then((data) => {
      if (data.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.SEARCH_ADDRESS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.SEARCH_ADDRESS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SEARCH_ADDRESS_FAILURE,
      error,
    };
  }
}


function paywithordo() {
  return (dispatch) => {
    TenantService.paywithordo().then((data) => {
      if (data.status) {
        console.log('data', data);
        window.open(data?.data);
      } else {
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };
}

function confirmGaurantor(data, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.confirmGaurantor(data, id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        dispatch(getIdentityData(id));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.CONFIRM_GAURANTOR_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_FAILURE,
      error,
    };
  }
}

function viewOpenFlowDepositCertificate(data,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.viewOpenFlowDepositCertificate(data).then((data) => {
      if(cb){
        cb()
      }
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    })
  };

  function request() {
    return { type: tenantConstants.CONFIRM_GAURANTOR_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_FAILURE,
      error,
    };
  }
}
function acceptMoveinApi(id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.acceptMoveinApi(id).then((data) => {
      if(cb){
        cb()
      }
      if (data.status) {
        dispatch({type: types.SHOW_ALERT, alertType: 'success', text: 'Move in monies payment marked as paid!' });
        dispatch(success(data));
      } else {
         if(cb){
        cb()
      }
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'success',
        text: 'Someting went wrong!',
      });
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.CONFIRM_GAURANTOR_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.CONFIRM_GAURANTOR_FAILURE,
      error,
    };
  }
}

function saveIdentityData(OBJ, id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveIdentityData(OBJ, id).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: 'Guarantor Information added successfully!',
        });
        if(cb){
          cb()
        }
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.SAVE_IDEN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.SAVE_IDEN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SAVE_IDEN_FAILURE,
      error,
    };
  }
}

function getIdentityData(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getIdentityData(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_IDEN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_IDEN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_IDEN_FAILURE,
      error,
    };
  }
}

function postRtrCode(obj, cb, getProfileapi) {
  console.log('getProfileapi', getProfileapi);
  return (dispatch) => {
    TenantService.postRtrCode(obj).then((data) => {
      if (data?.status) {
        if (cb) {
          cb(data);
          dispatch(showToaster('', 'Data saved suceessfully!'));
          if (getProfileapi) {
            dispatch(getProfile());
          }
        }
      } else {
        if (cb) {
          cb(data);
        }
        if (data?.message) {
          dispatch(showToaster('error', data?.message?.toString()));
        } else {
          dispatch(showToaster('error', 'Something went worng!'));
        }
      }
    });
  };
}



function uploadManualAST(data, id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadManualAst(data, id).then((data) => {
      if (data) {
        if(cb){
          cb()
        }
        dispatch(success(data));
      } else {
        if(cb){
          cb()
        }
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.UPLOAD_AST_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.UPLOAD_AST_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPLOAD_AST_FAILURE,
      error,
    };
  }
}

function getFccRequestedReferenceDocs(id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFccRequestedReferenceDocs(id).then((data) => {
      if (data) {
        dispatch(success(data));
        cb(data);
      } else {
        dispatch(failure(data));
        cb([]);
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_FCC_REQ_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_FCC_REQ_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_FCC_REQ_REF_DOC_FAILURE,
      error,
    };
  }
}

function uploadReferenceDocs(data, id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadRefDocs(data,id).then((data) => {
      if (data) {
        dispatch(success(data));
        cb(false);
      } else {
        dispatch(failure(data));
        cb(true);
      }
    });
  };

  function request() {
    return { type: tenantConstants.UPLOAD_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.UPLOAD_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPLOAD_REF_DOC_FAILURE,
      error,
    };
  }
}

function uploadRightToRentDocs(data, id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadRightToRentDocs(data, id).then((data) => {
      if (data) {
        dispatch(success(data));
        dispatch(getIdentityData(id));
        cb(false);
      } else {
        dispatch(failure(data));
        cb(true);

        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.UPLOAD_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.UPLOAD_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPLOAD_REF_DOC_FAILURE,
      error,
    };
  }
}

function uploadTenantRef(data, id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadTenantRef(data, id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        cb(false);
      } else {
        dispatch(failure(data));
        cb(true);
        dispatch(showToaster('error', data.message.toString()));
      }
    });
  };
  

  function request() {
    return { type: tenantConstants.UPLOAD_TENANT_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.UPLOAD_TENANT_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPLOAD_TENANT_REF_DOC_FAILURE,
      error,
    };
  }
}

function uploadRefDocsNoAgent(data, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.uploadRefDocsNoAgent(data).then((data) => {
      if (data.status) {
        dispatch(success(data));
        cb(false);
      } else {
        dispatch(failure(data));
        cb(true);
        dispatch(showToaster('error', data.message.toString()));
      }
    });
  };
  

  function request() {
    return { type: tenantConstants.UPLOAD_TENANT_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.UPLOAD_TENANT_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPLOAD_TENANT_REF_DOC_FAILURE,
      error,
    };
  }
}

function deleteRefDocument(data, id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteRefDoc(data, id).then((data) => {
      if (data) {
        if(cb){
          cb(data)
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.DELETE_REF_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.DELETE_REF_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.DELETE_REF_DOC_FAILURE,
      error,
    };
  }
}

function delCer(cerName, id, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.delCer(cerName, id).then((data) => {
      if (data.status) {
        if (cb) {
          cb(data.status);
        }
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.DEL_CER_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.DEL_CER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.DEL_CER_FAILURE,
      error,
    };
  }
}

function buyCer(cerName, data, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.buyCer(cerName, data, id).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.BUY_CER_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.BUY_CER_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.BUY_CER_FAILURE,
      error,
    };
  }
}

function getHoldingDeposit(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getHoldingDeposit(id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_HOLDING_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_HOLDING_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_HOLDING_FAILURE,
      error,
    };
  }
}

function getOnboardedTeanants() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getOnboardedTeanants().then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TEANANTS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TEANANTS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TEANANTS_FAILURE,
      error,
    };
  }
}

function toggleComplete(url) {
  return (dispatch) => {
    dispatch(request());
    TenantService.toggleComplete(url).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TOGGLE_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TOGGLE_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TOGGLE_FAILURE,
      error,
    };
  }
}

function getDocuments(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getDocuments(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TEANANTS_DOC_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TEANANTS_DOC_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TEANANTS_DOC_FAIL,
      error,
    };
  }
}

function cleanGetFlowInfo() {
  return (dispatch) => {
    dispatch({ type: tenantConstants.GET_FLOWINFO_RESET });
  };
}

function saveTeanantDetails(payload, flowId, text,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveTeanantDetails(payload, flowId).then((data) => {
      if (data) {
        if(cb){
          cb()
        }
        dispatch(success(data.flows));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: text ? text : 'Details Updated Successfully!',
        });
      } else {
        dispatch(failure(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Something Went wrong',
        });
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.ADD_TEANANTS_DETAILS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.ADD_TEANANTS_DETAILS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.ADD_TEANANTS_DETAILS_FAIL,
      error,
    };
  }
}

function toggleSeenAll(payload,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.toggleSeenAll(payload).then((data) => {
      if (data?.status) {
        dispatch(success(data.flows));
        cb(data?.status);
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: 'Document status updated Successfully!',
        });
      } else {
        dispatch(failure(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Something Went wrong',
        });
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.UPDATE_ISSEEN_REQUEST };
  }

  function success(data) {
    return { type: tenantConstants.UPDATE_ISSEEN_SUCCESS };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPDATE_ISSEEN_FAILURE,
      error,
    };
  }
}
function toggleSeen(payload, flowId, cb,isHowTorent) {
  return (dispatch) => {
    dispatch(request());
    TenantService.toggleSeen(payload, flowId,isHowTorent).then((data) => {
      if (data?.status) {
        dispatch(success(data.flows));
        cb(data?.status);
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: 'Document status updated Successfully!',
        });
      } else {
        dispatch(failure(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Something Went wrong',
        });
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.UPDATE_ISSEEN_REQUEST };
  }

  function success(data) {
    return { type: tenantConstants.UPDATE_ISSEEN_SUCCESS };
  }

  function failure(error) {
    return {
      type: tenantConstants.UPDATE_ISSEEN_FAILURE,
      error,
    };
  }
}

function deleteDoc(flowId, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.deleteDoc(flowId).then((data) => {
      if (data?.status) {
        dispatch(success(data.flows));
        cb(data?.status);
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'success',
          text: 'Document removed Successfully!',
        });
      } else {
        dispatch(failure(data));
        dispatch({
          type: types.SHOW_ALERT,
          alertType: 'error',
          text: 'Something Went wrong',
        });
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.DELETE_DOCS };
  }

  function success(data) {
    return { type: tenantConstants.DELETE_DOCS_SUCCESS };
  }

  function failure(error) {
    return {
      type: tenantConstants.DELETE_DOCSFAILURE,
      error,
    };
  }
}

function saveSlectedProperty(data) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveSlectedProperty(data.property_id).then((data) => {
      if (data?.data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.SAVE_TEANANTS_PROPERTY_REQUEST };
  }

  function success(propertyData) {
    return {
      type: tenantConstants.SAVE_TEANANTS_PROPERTY_SUCCESS,
      data: propertyData,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SAVE_TEANANTS_PROPERTY_FAILURE,
      error,
    };
  }
}

function getFlowInfo(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getFlowInfo(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_FLOWINFO_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_FLOWINFO_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_FLOWINFO_FAILURE,
      error,
    };
  }
}

function getTeanants(flowId) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTeanants(flowId).then((data) => {
      if (data?.data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TEANANTS_DETAILS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TEANANTS_DETAILS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TEANANTS_DETAILS_FAILURE,
      error,
    };
  }
}

function getRentalOffers(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getRentalOffers(id).then((data) => {
      if (data?.data) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_RENTAL_OFFERS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_RENTAL_OFFERS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_RENTAL_OFFERS_FAILURE,
      error,
    };
  }
}

function addRentalOffer(id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addRentalOffer(id).then((data) => {
      if(cb){
        cb()
      }
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        
      }
    });
  };

  function request() {
    return { type: tenantConstants.ADD_RENTAL_OFFERS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.ADD_RENTAL_OFFERS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.ADD_RENTAL_OFFERS_FAILURE,
      error,
    };
  }
}

function getMoveIn(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getMoveIn(id).then((data) => {
      if (data.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_MOVEIN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_MOVEIN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_MOVEIN_FAILURE,
      error,
    };
  }
}

function getVerificationReferencing() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getVerificationReferencing().then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_VERIFICATION_REF_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_VERIFICATION_REF_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_VERIFICATION_REF_FAILURE,
      error,
    };
  }
}

function addMoveIn(payload) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addMoveIn(payload).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.ADD_MOVEIN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.ADD_MOVEIN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.ADD_MOVEIN_FAILURE,
      error,
    };
  }
}

function getTenancyInfo(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTenancyInfo(id).then((data) => {
      if (data?.status) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TENANCY_INFO_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TENANCY_INFO_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TENANCY_INFO_FAILURE,
      error,
    };
  }
}

function getNHA() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getNHA().then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_NHA_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_NHA_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_NHA_FAILURE,
      error,
    };
  }
}

function saveMoveinData(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveMoveinData(payload, id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.SAVE_MOVEIN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.SAVE_MOVEIN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SAVE_MOVEIN_FAILURE,
      error,
    };
  }
}

function arrangeCheckIn(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.arrangeCheckIn(payload, id).then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.SAVE_CHECKIN_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.SAVE_CHECKIN_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SAVE_CHECKIN_FAILURE,
      error,
    };
  }
}

function getTdsData() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTdsData().then((data) => {
      if (data?.status) {
        dispatch(success(data?.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_TDS_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_TDS_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_TDS_FAILURE,
      error,
    };
  }
}

function getAST(id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.getAST(id).then((data) => {
      if (data.status) {
        dispatch(success(data));
        dispatch(getTenancyInfo(id));
      } else {
        dispatch(failure(data));
      }
    });
  };

  function request() {
    return { type: tenantConstants.GET_AST_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.GET_AST_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.GET_AST_FAILURE,
      error,
    };
  }
}

function addTenancyInfo(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.addTenancyInfo(payload, id).then((data) => {
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.ADD_TENANCY_INFO_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.ADD_TENANCY_INFO_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.ADD_TENANCY_INFO_FAILURE,
      error,
    };
  }
}


function requestHoldingDeposit(payload, id) {
  return (dispatch) => {
    dispatch(request());
    TenantService.requestHoldingDeposit(payload, id).then((data) => {
      if (data) {
        dispatch(success(data.data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.REQUEST_HOLDING_DEPOSIT_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.REQUEST_HOLDING_DEPOSIT_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.REQUEST_HOLDING_DEPOSIT_FAILURE,
      error,
    };
  }
}
function getTenantInfo() {
  return (dispatch) => {
    dispatch(request());
    TenantService.getTenantInfo().then((data) => {
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };
  function request() {
    return { type: tenantConstants.GET_TENANT_REQUEST };
  }
  function success(data) {
    return {
      type: tenantConstants.GET_TENANT_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: tenantConstants.GET_TENANT_FAILED,
      error,
    };
  }
}

function saveTenantInfo(payload, cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.saveTenantInfo(payload)
      .then((data) => {
        if (data.status) {
          if (cb) {
            cb();
          }
          dispatch(success(data));
        } else {
          dispatch(failure(data));
          dispatch(showToaster('error', data?.message ? data?.message : 'Something went worng!'));
        }
      })
      .catch((data) => {
        dispatch(failure());
        dispatch(showToaster('error', data ? data : 'Something went worng!'));
      });
  };

  function request() {
    return { type: tenantConstants.SAVE_TENANT_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.SAVE_TENANT_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.SAVE_CER_FAILURE,
      error,
    };
  }
}

function confirmDeposit(id,cb) {
  return (dispatch) => {
    dispatch(request());
    TenantService.confirmDeposit(id).then((data) => {
      if(cb){
        cb()
      }
      if (data.status) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
        // dispatch(alertActions.error(data.message.toString()));
      }
    });
  };

  function request() {
    return { type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_REQUEST };
  }

  function success(data) {
    return {
      type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_SUCCESS,
      data,
    };
  }

  function failure(error) {
    return {
      type: tenantConstants.CONFIRM_HOLDING_DEPOSIT_FAILURE,
      error,
    };
  }
}
function getInsuranceDocs(cb) {
  return (dispatch) => {
    TenantService.getInsurancePurchase()
      .then((data) => {
        if (data.status) {
          if (cb) {
            cb(data.data);
          }
        } else {
          if (data.message) {
            dispatch(showToaster('error', data.message));
          }
        }
      })
      .catch((e) => {
        dispatch(showToaster('error', e));
      });
  };
}
function payWithVoucher(payload,cb) {
  return (dispatch) => {
   
    TenantService.payWithVoucher(payload).then((data) => {
      if (data.status) {
        cb(data)
      } else {
        cb(data)
      }
    }).catch((err)=>{
      cb(false)
    });
  };

}

function getOpenflowData(payload,cb) {
  return (dispatch) => {
    dispatch(request())
    TenantService.getOpenflowData(payload).then((data) => {
      dispatch(success())
      if (data.status) {
        cb(data.data)
      } else {
        cb(data.data)
      }
    }).catch((error)=>{
      dispatch(failure(error))
      cb(false)
    });
  };
  function request() {
    return { type: tenantConstants.GET_RENTAL_OFFERS_REQUEST };
  }
  function success() {
    return {
      type: tenantConstants.GET_RENTAL_OFFERS_SUCCESS
    };
  }
  function failure(error) {
    return {
      type: tenantConstants.GET_RENTAL_OFFERS_FAILURE,
      error,
    };
  }
}
function getSignAstStatus(ID,cb) {
  return (dispatch) => {
    TenantService.getSignAstStatus(ID).then((data) => {
      console.log("getOpenflowData",data)
      if (data.status) {
        cb(data.data)
      } else {
        cb(data.data)
      }
    }).catch((err)=>{
      cb(false)
    });
  };

}
function getFccPropertiesData(cb) {
  return (dispatch) => {
    TenantService.getFccProperties().then((data) => {
      if (data.status) {
        cb(data)
      } else {
        cb(data)
      }
    }).catch((err)=>{
      cb(false)
    });
  };
}
function getReferenceFromId(payload,cb) {
  return (dispatch) => {
    TenantService.getRefFromId(payload).then((data) => {
      if (data.status) {
        cb(data)
      } else {
        cb(data)
      }
    }).catch((err)=>{
      cb(false)
    });
  };
}

