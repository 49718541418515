import styled from '@emotion/native';
import React from 'react';

export interface ComboboxListProps {
  label?: string;
  width: number;
  height: number;
  children: React.ReactNode;
}

const StyledComboboxList = styled.View<ComboboxListProps>`
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.theme.colors.other['border-neutral']}`};
  background-color: ${(props) => props.theme.colors.elements.invert};
  position: absolute;
  min-height: 172px;
  z-index: 10000;
  ${({ label, height, width }) => `
  width: ${width ? width : '100%'};
  top: ${label ? (height ? height + 40 : 84) : height + 5}px;
`};
`;

function ComboboxList({ children, label, width, height }: ComboboxListProps) {
  return (
    <StyledComboboxList label={label} width={width} height={height}>
      {children}
    </StyledComboboxList>
  );
}

export default ComboboxList;
