import React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Image,
    Linking,
} from 'react-native';
import { useTheme } from '@emotion/react';
import { ODSText } from '../../../atoms/ODSText';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { useNavigation } from '@react-navigation/native';

const NotificationItem = ({
    title,
    content,
    subText,
    image,
    icon,
    redirect,
    read,
    file,
    onPress,
    onDelete,
    webRedirect
}: any) => {
    const { colors } = useTheme();
    const styles = getStyles(colors, read);
    const { navigate } = useNavigation();
    return (
        <View style={styles.container}>
            <View style={styles.row}>
                {icon && <View style={styles.iconContainer}>
                    <ODSIcon size={32} icon='TB-Calendar' color={colors.button.main} />
                </View>}
                <View style={{ marginRight: 12, width: '92%' }}>
                    {subText && <ODSText marginBottom={4} type='sm' color={colors.text.primary}>{subText}</ODSText>}
                    {title && <ODSText type='md-bold' color={colors.text.primary}>{title}</ODSText>}
                    {content && <ODSText marginTop={4} type='sm' color={colors.text.primary}>{content}</ODSText>}
                </View>
                {onDelete && <TouchableOpacity onPress={onDelete}> <ODSIcon size={24} icon='TB-Trash' color={colors.main.red[500]} /></TouchableOpacity>}
            </View>
            {file && (
                <View style={styles.fileRow}>
                    <ODSIcon size={20} icon='TB-Download' color={colors.button.main} />
                    <ODSText marginLeft={8} type='sm-bold' color={colors.button.main}>{file}</ODSText>
                </View>
            )}
            {image && (
                <Image
                    style={styles.image}
                    source={{ uri: 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1200' }}
                />
            )}
            {(redirect || webRedirect) && (
                <TouchableOpacity
                    onPress={() => {
                        onPress();
                        setTimeout(() => {
                            webRedirect ? window.open(webRedirect, '_self') : Linking.openURL(redirect)
                        }, 300);
                    }}
                    style={styles.button}>
                    <ODSText marginRight={4} type='sm-bold' color={colors.text.inverted}>View more</ODSText>
                    <ODSIcon icon='TB-Arrow-Right' size={18} color={colors.text.inverted} />
                </TouchableOpacity>
            )}
        </View>
    );
};

const getStyles = (colors, read) =>
    StyleSheet.create({
        container: {
            borderBottomColor: '#E2E8F0',
            borderBottomWidth: 1,
            width: '100%',
            paddingHorizontal: 20,
            paddingVertical: 24,
            backgroundColor: read ? 'transparent' : colors.elements['color-bg'],
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        iconContainer: {
            marginRight: 20,
        },
        fileRow: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 12,
        },
        image: {
            width: '100%',
            borderRadius: 8,
            marginTop: 12,
            alignSelf: 'center',
            height: 150,
        },
        button: {
            flexDirection: 'row',
            marginTop: 12,
            width: 120,
            height: 32,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.button.main,
        },
    });

export default NotificationItem;
