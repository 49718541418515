import { types } from "../../actionTypes";
import {
  SERVER_URL,
  _post,
  _get,
  _delete,
} from "../../../helpers/connectionHelpers";

export const addNotification = (isArchived) => {
  return (dispatch) => {
    let url = `${SERVER_URL}/notification`;
    if(isArchived){
      url = `${SERVER_URL}/notification/archived`;
    }
    _get(
      url,
      {},
      {},
      (res) => {
        if(isArchived){
          dispatch({ type: types.ADDNOTIFICATIONARCHIVED, payload: res?.data });
        }else
        dispatch({ type: types.ADDNOTIFICATION, payload: res?.data });
      },
      (err) => {}
    );
  };
};

export const onNotificationOpen = () => {
  return (dispatch) => {
    let url = `${SERVER_URL}/notification/mark_as_read`;
    _post(
      url,
      {},
      JSON.stringify({}),
      (res) => {
        dispatch(addNotification());
      },
      (err) => {}
    );
  };
};

export const onNotificationRemove = (id,isArchioved) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_NOTIFICATION_REQUEST });
    let url = `${SERVER_URL}/notification/${id}`;
    _delete(
      url,
      {},
      JSON.stringify({}),
      (res) => {
        if(res.status){
            dispatch(addNotification(isArchioved));
          dispatch({ type: types.REMOVE_NOTIFICATION_SUCCESS });
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "success",
            text: "Notification removed successfully!",
          });
        }else{
          dispatch({
            type: types.SHOW_ALERT,
            alertType: "error",
            text: "Something went wrong!",
          });
        }
  
      },
      (err) => {
        dispatch({ type: types.REMOVE_NOTIFICATION_FAILED });
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "error",
          text: "Something went wrong!",
        });
      }
    );
  };
};
export const getNotifications = (payload = {}, callBack) => {
  return (dispatch) => {
    _get(
      `${SERVER_URL}/notifications?`,
      {},
      payload,
      (response) => {
        if (callBack) {
          callBack(response.data);
        }
      },
      (error) => {
        if (callBack) {
          callBack(error);
        }
      }
    );
  };
};

export const getNotificationsCount = () => {
  return (dispatch) => {
    _get(
      `${SERVER_URL}/notifications/count`,
      {},
      {},
      (response) => {
        if (response?.status) {
          dispatch({ type: types.SET_NOTIFICATION_COUNT, payload: response?.data?.unreadCount });
        }
      },
      () => {
      }
    );
  };
};

export const markAsRead = (id, callBack) => {
  return (dispatch) => {
    _post(
      `${SERVER_URL}/notifications/read/${id}`,
      {},
      JSON.stringify({}),
      (response) => {
        dispatch(getNotificationsCount());
        if (callBack) {
          callBack(response);
        }
      },
      (error) => {
        if (callBack) {
          callBack(error);
        }
      }
    );
  };
};

export const markAllRead = (callBack) => {
  return (dispatch) => {
    _post(
      `${SERVER_URL}/notifications/readAll`,
      {},
      JSON.stringify({}),
      (response) => {
        dispatch(getNotificationsCount());
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "success",
          text: "All marked as read",
        });
        if (callBack) {
          callBack(response);
        }
      },
      (error) => {
        if (callBack) {
          callBack(error);
        }
      }
    );
  };
};

export const deleteNotification = (id, callBack) => {
  return (dispatch) => {
    _delete(
      `${SERVER_URL}/notifications/${id}`,
      {},
      JSON.stringify({}),
      (response) => {
        dispatch({
          type: types.SHOW_ALERT,
          alertType: "success",
          text: "Deleted successfully",
        });
        if (callBack) {
          callBack(response);
        }
      },
      (error) => {
        if (callBack) {
          callBack(error);
        }
      }
    );
  };
};
