import React from "react";
import { Text, View } from "react-native";
import { ODSText } from "../atoms/ODSText";
export const Typography = () => {
  return (
    <View>
      <Text style={{ textAlign: 'center', marginVertical: 10, fontSize: 28, fontWeight: 'bold' }}>Typography</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {['h1', 'h2', 'h3', 'h4', 'h5', 'h5', 'h6', 'button', 'md', 'sm', 'xs'].map((e) => <ODSText marginLeft={10} color="black" type={e}>{e}</ODSText>)}
      </View>
      <Text style={{ backgroundColor: '#3a3b3c', color: 'white', padding: 10, textAlign: 'center' }}>{`
  import { ODSText } from 'openbrix-design-system';

  <ODSText type={'h1'} {...TextProps} > Heading 1 </Text>`}</Text>
    </View>

  )
}