import idx from "idx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect } from "react";
import { Image, TouchableOpacity, View, FlatList, Text } from "react-native";

import Modal  from "react-native-modal";
import { LogoutApi } from "../../redux/actions/auth";
import { LinearGradient } from "expo-linear-gradient";
import { getProfile } from "../../redux/actions/auth";
import { COLORS } from "../../constants/colorPallete.js";
import { DrawerWrapper, MenuItem } from "./notificationDrawerStyle";
import { Link } from "@react-navigation/native";

const customNotificationModal = (props) => {
  const { borderColor } = props;
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props?.notificationReducer?.data)
      setList(props?.notificationReducer?.data);
  }, [props?.notificationReducer]);

  const renderItem = ({ item }) => {

      let bwidth = 1
      let width = 180
      if ( item.is_viewed == false ) {
          bwidth = 3
          width = 172
      }

    return (
      <Link to={item.context?.app_redirect_url}>
      <TouchableOpacity
        onPress={()=>{
          props.setNotificationDrawerState(false)
        }}
        style={{
          borderColor: "white",
          borderRadius: 10,
          borderWidth: bwidth,
          flexDirection: "row",
          padding: 15,
          marginTop: 20,
        }}
      >
          {item.is_viewed == false && <Text style={{color:"red", fontSize:19}}>!</Text>}
        <Image style={{ height: 30, width: 30 }} source={item.image? require(`../../assets/images/${item.image}`) : '' }/>
        {/* TODO @hitest: add link here if item.context.redirect_url */}

        <Text style={{ marginLeft: 0, width:width, color: "#ffffff", alignSelf: "center" }}>
          {item.context.description}
        </Text>
      </TouchableOpacity>
      </Link>

    );
  };

  return (
    <Modal
      isVisible={props.drawerState}
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInTiming={1000}
      animationOutTiming={1000}
      coverScreen={false}
      tranparent={true}
      hasBackdrop={false}
      backdropOpacity={0}
      onBackdropPress={() => props.setNotificationDrawerState(false)}
      style={{
        margin: 0,
        width: "100%",
        justifyContent: "flex-start",
        flex: 1,
        height:'100%',
        overflow:"auto",
       
      }}
    >
      <View
        style={{
          flex: 1,
          zIndex:1000,
         alignItems:"flex-end",
          width: "100%",
        }}
        onStartShouldSetResponder={() => props.setNotificationDrawerState(false)}
      >
        <DrawerWrapper>
          <LinearGradient
           colors={
            borderColor
              ? [borderColor, borderColor]
              : [COLORS.gradientColor3,COLORS.gradientColor4]
          }
            style={{
              right: 0,
              minWidth:'200px',
              minHeight: "80%",
              borderTopLeftRadius: 40,
              borderBottomLeftRadius: 40,
              paddingBottom: 50,
            }}
            start={[0, 0.3]}
            end={[1, 0.7]}
          >
            <TouchableOpacity
              // onPress={() => props.setNotificationDrawerState(false)}
            >
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 30,
                  paddingRight: 20,
                  justifyContent: "flex-end",
                }}
              >
                <MenuItem>Notification</MenuItem>
                <Image
                  style={{
                    tintColor: "white",
                    height: 24,
                    width: 24,
                    marginLeft: 10,
                  }}
                  source={require("../../assets/images/notification.png")}
                />
              </View>
            </TouchableOpacity>
            <View style={{ flex: 1, paddingRight: 20, paddingLeft: 20,overflow:"auto" }}>
              <FlatList
                data={list ? list : []}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
              />
            </View>
          </LinearGradient>
        </DrawerWrapper>
      </View>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  
  return {
    token: idx(state, (_) => _.appReducer.loginData.data.token),
    profile: state.authReducer.profile,
    notificationReducer: state.notificationReducer,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LogoutApi,
      getProfile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(customNotificationModal);
