import React, { useState } from "react";
import { View, TouchableOpacity, StyleSheet, Linking, Dimensions, Platform, Text } from "react-native";
import { useTheme } from "@emotion/react";
import { ODSText } from "../../atoms/ODSText";
import { ODSInput } from "../../atoms/ODSInput";
import { ODSIcon } from "../../atoms/ODSIcon";
import moment from "moment";
import ODSButton from "../../atoms/ODSButton";
import getScreenType from "../../../utils/getScreenType";

const MyDocs = ({ data, onDelete, addDoc, navigation, onArchive }) => {
    const { colors } = useTheme();
    const [searchText, setSearchText] = useState('');
    const layout = getScreenType();
    const width = Dimensions.get('window').width;
    const filteredData = data.filter((e) =>
        e?.filename.toLowerCase().includes(searchText.toLowerCase())
    );
    const renderShareButon = () => {
        return (
            <ODSButton
                lIcon={'TB-Add-Plus'}
                restStyle={{ width: 144, alignSelf: 'flex-end' }}
                type="primary"
                onPress={addDoc}
                themeColor={colors.main.purple}
                disabled={false}
            >
                Add Doc
            </ODSButton>
        )
    }
    return (
        <View style={styles.container}>
            <ODSText type="sm" textAlign="center" marginTop={32} marginBottom={20} color={colors.text.secondary}>
                Here you can search, view, upload and remove documents. All your past/deleted documents can be found in the document archive
            </ODSText>
            <ODSButton
                type='secondary'
                restStyle={{ marginTop: 24, width: 300, alignSelf: 'center', marginBottom: 12 }}
                themeColor={colors.main.purple}
                onPress={() => navigation.navigate('ArchiveDocuments')}
            >Document Archive</ODSButton>
            <View style={[styles.divider, {
                backgroundColor: colors.other['border-neutral'], marginVertical: layout == "web" ? 32 : 16,
            }]} />
            <ODSInput
                width={layout == "phone" ? width - 30 : 297}
                leftIcon="TB-Search"
                value={searchText}
                onChange={setSearchText}
                placeholder="Search documents"
            />
            <View style={{ height: 16 }} />
            {filteredData && filteredData?.length > 4 && renderShareButon()}

            <View style={[styles.docsContainer, { marginVertical: 24 }]}>
                {filteredData && filteredData?.length ? filteredData.map((e) => (
                    <View
                        key={e.id}
                        style={[
                            styles.docItem,
                            {
                                borderColor: colors.other['border-neutral'], shadowColor: '#101828',
                                width: layout == 'web' ? 407 : layout == 'tab' ? 298 : width - 30
                            }
                        ]}
                    >
                        <TouchableOpacity onPress={() => layout != 'web' ? Linking.openURL(e?.file_path) : window.open(e?.file_path)}>
                            <ODSIcon icon="TB-Download" size={32} color={colors.main.purple[500]} />
                        </TouchableOpacity>
                        <View style={styles.docTextContainer}>
                            <ODSText width='90%' numberOfLines={1} type="md-bold" color={colors.text.primary}>{e?.filename}</ODSText>
                            <ODSText type="sm" color={colors.text.secondary} marginTop={4}>
                                {e?.createdAt && moment(e.createdAt).format("DD/MM/YYYY")}
                            </ODSText>
                        </View>
                        {e?.hideDelete ? null : <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity
                                onPress={() => onArchive(e)}
                                style={[
                                    styles.deleteButton,
                                    { backgroundColor: colors.elements.color }
                                ]}
                            >
                                <ODSIcon icon="TBDoc" size={20} color={colors.elements.invert} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => onDelete(e)}
                                style={[
                                    styles.deleteButton,
                                    { backgroundColor: colors.feedback.error[400], marginLeft: 12 }
                                ]}
                            >
                                <ODSIcon icon="TB-Trash" size={20} color={colors.elements.invert} />
                            </TouchableOpacity>

                        </View>}
                    </View>
                )) :
                    <View style={{ justifyContent: "center", width: "100%" }}>
                        <ODSText type="button" alignSelf={"center"} textAlign="center" marginBottom={20} >
                            No matches for “search term” found
                        </ODSText>
                        <ODSText type="sm" textAlign="center" marginBottom={20}>
                            Double-check your search for any typos or try a different search term
                        </ODSText>
                    </View>
                }
            </View>
            {renderShareButon()}
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    divider: {
        height: 1,
    },
    docsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    docItem: {
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 12,
        padding: 24,
        borderWidth: 1,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: Platform.OS == "android" ? 0 : 5,
        marginBottom: 16
    },
    docTextContainer: {
        marginLeft: 16,
        flex: 1
    },
    deleteButton: {
        marginLeft: 'auto',
        width: 32,
        height: 32,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default MyDocs;
