import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import CustomTextInput from "../../../commonComponents/customTextInput/customTextInput";
import { gapi } from "gapi-script";
import { EmailValidation, PasswordValidation } from "../../../helpers/validation";
import { useDispatch, useSelector } from "react-redux";
import WideButton from "../../../commonComponents/wideButton";
import { COLORS } from "../../../constants/colorPallete";
import { login } from "../../../redux/actions/auth";
import { Typography } from "../../openFlow/style";
import SocialLogin from "./soicalLogin";
import StoreButtons from "../common/StoreButtons";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../helpers/firebase";

gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId: "684202173750-alebu8h72bs0938qr9c2o4c14rp9t8oo.apps.googleusercontent.com",
    plugin_name: "chat",
  });
});
const LandingSignin = (props) => {
  const [type, setType] = useState("web");
  const [token,setToken]=useState('');
  const [state, setState] = useState({
    tncStatus: false,
    contactStatus: false,
    buyerEmail: "",
    buyerPassword: "",
    buyerEmailErrorMessage: "",
    buyerPasswordErrorMessage: "",
    selectedTab: "Tab1",
  });

  const loading = useSelector((state) => state.authReducer.loginLoading);

  React.useEffect(() => {
    requestPermission();
    
    if (props?.route?.params?.from) {
      setState({
        ...state,
        from: props?.route?.params?.from ? props?.route?.params?.from : "",
      });
    }
  }, [props?.route?.params?.from]);

  useEffect(() => {
    if (props?.route?.params?.email) {
      setState({
        ...state,
        buyerEmail: props?.route?.params?.email ? props?.route?.params?.email : "",
      });
    }
  }, [props?.route?.params?.email]);

  const { isTLYF } = props;

  const dispatch = useDispatch();

  const requestPermission= async ()=>{
    const permission=await Notification.requestPermission();
    console.log(permission,"PERMISISION")
    if(permission==="granted"){
     const token=await getToken(messaging,{vapidKey:'BLclf2caX7flT-cHYPLMYIzzGlnHmxT6jesQ7SWj9sDYxZtfRcpmhnk04YivjX4p5S4mn2M6_tE5Zvcvmg0s87A'});
     console.log("TOKEN",token)
     setToken(token)
    }
    else{
    //  alert("Notification Permission Denied")
    }
     }
  const _onLogin = () => {
    const {
      buyerEmail,
      buyerPassword,
    } = state;
    let emailCheck = EmailValidation(state?.buyerEmail);
    let passwordCheck = PasswordValidation(state?.buyerPassword);
    if (emailCheck.status && passwordCheck.status) {
      dispatch(
        login(
          {
            email: buyerEmail.trim(),
            password: buyerPassword,
            user_type: 0,
            fcm_registration_token:token
          },
          props.navigation,
        ),
      );
    } else {
      setState({
        ...state,
        buyerEmailErrorMessage: emailCheck.status ? "" : emailCheck.msg,
        buyerPasswordErrorMessage: passwordCheck.status ? "" : passwordCheck.msg,
      });
    }
  };
  return (
    <>
      <View
        style={{
          width: "100%",
          padding: "3%",
        }}
      >
        <Typography
          color={isTLYF ? COLORS.LGREEN : COLORS.LIGHTPINK1}
          raj
          size={"40px"}
          bold={"700"}
        >
          Login
        </Typography>
        <TouchableOpacity
          onPress={() => {
            props?.onRegisterClick();
            //state?.from ? navigate('Register', { from: state?.from }) : navigate('Register')
          }}
        >
          <Typography color={COLORS.LABELGREY} raj size={"18px"} bold={"500"}>
            Don't have an Account?{" "}
            <Typography
              color={isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2}
              raj
              size={"18px"}
              bold={"500"}
            >
              Register Now
            </Typography>
          </Typography>
        </TouchableOpacity>

        <View
          style={{
            height: 0.5,
            width: "100%",
            backgroundColor: COLORS.GREY1,
            marginVertical: 20,
          }}
        />
        <CustomTextInput
          id="login-Email"
          width="100%"
          bRadius={50}
          bColor={"#E2E2E2"}
          titleComponent={
            <Typography color={COLORS.BLACK} raj size={"20px"} bold={"600"}>
              Email
            </Typography>
          }
       
          onChangeText={(email) =>
            setState({
              ...state,
              buyerEmail: email,
              buyerEmailErrorMessage: "",
            })
          }
          showError={state?.buyerEmailErrorMessage == "" ? false : true}
          prefilledValue={state?.buyerEmail}
          errorMessage={state?.buyerEmailErrorMessage}
        />

        <CustomTextInput
          id="login-Password"
          width="100%"
          titleComponent={
            <Typography color={COLORS.BLACK} raj size={"20px"} bold={"600"}>
              Password
            </Typography>
          }
          bRadius={50}
          renderRightComponent
          eyePosition={'52%'}
          bColor={"#E2E2E2"}
          
          onChangeText={(password) =>
            setState({
              ...state,
              buyerPassword: password,
              buyerPasswordErrorMessage: "",
            })
          }
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              _onLogin();
            }
          }}
          password={true}
          showError={state?.buyerPasswordErrorMessage == "" ? false : true}
          errorMessage={state?.buyerPasswordErrorMessage}
        />
       
        <View style={{ marginTop: 20 }}>
          <WideButton
            width={"100%"}
            id="loginBtn"
            onPress={() => _onLogin()}
            gradiant
            isLGreen={isTLYF}
            childText="Login"
            height={"55px"}
            gradBorderRadius={50}
            isRaj={true}
            textSize={25}
          />
        </View>
        <SocialLogin
          navigation={props.navigation}
        />
        <Typography
          underline
          top={"10px"}
          color={isTLYF ? COLORS.GREEN : COLORS.LIGHTPINK2}
          raj
          size={"18px"}
          bold={"500"}
          onPress={() => {
            props?.onForgetClick(true)
            // props.navigation("Password Reset");
          }}
        >
          Forgotten Password?
        </Typography>
        <StoreButtons/>

      </View>
    </>
  );
};
export default LandingSignin;
