import React, { useState } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import FilePicker from '../../../commonComponents/filePicker/filepicker';
import WideButton from '../../../commonComponents/wideButton';
import { COLORS } from '../../../constants/colorPallete';
import getPlatformType from '../../../helpers/getPlatform';
import { LineSaperator } from '../../tds/style';
import { tenantActions } from '../openFlowRedux/tenant.actions';
import { Typography } from '../style';

const RequestDocs = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [docObj, setDocObj] = useState([]);
  const { item, tenant, width, index, docLength,fcc_applicant_id } = props;



  return (
    <>
      <View style={{ flexDirection: layoutType == 'web' ? 'row' : 'column' }}>
        <View style={{ width: '100%', flex: layoutType == 'web' ? 0.6 : 'none' }}>
          <Typography align={"left"} color={COLORS.BLACK} size={'14px'} bold={'700'} bottom={'10px'}>
            {item?.document_name ? item?.document_name : ''}
          </Typography>
          <FilePicker
            fileName={''}
            file={[]}
            width={width}
            onMultipleFileSelect={(val) => {
              if (val?.data && val?.data?.length) {
                let ModifiedDoc = val?.data?.map((payload, index) => {
                  const payload1 =  {
                    url: payload.openbrixName,
                    name: payload.name,
                    document_id: item?.document_id,
                    fcc_applicant_id
                  };
                  if(tenant?.fccReference && tenant?.fccReference?.fcc_references_updates && tenant?.fccReference?.fcc_references_updates?.length > 0) {
                    payload1.fcc_applicant_id = tenant?.fccReference?.fcc_references_updates[0].fcc_applicant_id;
                  }
                  // if(fcc){
                  //   payload1.fcc_applicant_id=props?.fcc_applicant_id
                  // }
                  return payload1;
                });
                setDocObj([...docObj, ...ModifiedDoc]);
              }
            }}
          />
          {docObj && docObj?.length ? (
            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <WideButton
                borderRadius={15}
                childText="Confirm & Upload"
                buttonType={'solid'}
                isLGreen
                style={{ alignSelf: 'center', marginTop: 10 }}
                width={'100%'}
                onPress={() =>{
                  if(props?.fcc){
                    dispatch(
                      tenantActions.uploadRefDocsNoAgent({ documents: docObj,branch:props?.branch }, (e) => {
                        setDocObj([]);
                        props.refreshApi()
                      })
                    )
                  }
                  else{
                    dispatch(
                      tenantActions.uploadTenantRef({ documents: docObj }, props.flowId, (e) => {
                        setDocObj([]);
                        props.refreshApi()
                      })
                    )
                  }
                }     
                }
              />
            </View>
          ) : null}
        </View>
        <View
          style={{
            width: '100%',
            flex: layoutType == 'web' ? 0.4 : 'none',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          {docObj.length
            ? docObj?.map((item, index2) => (
                <Typography
                  key={index2}
                  color={COLORS.BLACK}
                  size={'16px'}
                  bold={'900'}
                  top={'10px'}
                >
                  {item?.name}
                </Typography>
              ))
            : null}
        </View>
      </View>
      {docLength == index + 1 ? null : (
        <LineSaperator color={COLORS.BLACK} style={{ marginVertical: 20 }} />
      )}
    </>
  );
};
export default RequestDocs;
