import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import getPlatformType from '../../helpers/getPlatform';
import { BoxShadow, Wrapper } from './styles';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { TenantService } from '../openFlow/openFlowRedux/tenant.service';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import { Typography } from '../../../openbrix-design-system/ui/playground/Typography';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { useTheme } from '@emotion/react';
import { useNavigation } from '@react-navigation/native';
const WIN_HEIGHT = Dimensions.get('window').height;


const FccChooseAddress = (props) => {
    const dispatch = useDispatch();
    const layoutType = getPlatformType();
    const [page, setPage] = React.useState(1);
    const { navigate } = useNavigation();
    const [properties,setProperties]=useState([]);

    const { colors } = useTheme();;
    const themeObj = useTheme();;

      const getProperties=()=>{
       dispatch(tenantActions.getFccPropertiesData((data)=>
   {
        if(data?.status){
            setProperties(data?.data?.references?.reference)
        }
    }
    ))
      }
      useEffect(()=>{
      getProperties();
      },[])

    const StatusButton = (type) => {
        if (type === "COMPLETED") {
            return 'Completed';
        } else if (type=="NOT STARTED") {
            return 'Not Started';
        } else if (type=="IN PROGRESS") {
            return 'Pending';
        } else if (type=="ACTION REQUIRED") {
            return 'Action Required';
        } else if (type == 6) {
            return 'In Progress';
        } else {
            return 'Pending';
        }
    };


    const renderList = () => {
        return (
            <View style={{ flexWrap: 'wrap', zIndex: 1000, flexDirection: 'row', gap: 20, marginTop: 24, justifyContent: 'center' }}>
                {properties.map((e) =>
                    <TouchableOpacity style={{ width: layoutType == 'phone' ? '100%' : layoutType == 'web' ? 357 : 279 }} onPress={() => navigateAction(props.navigation, 'FccProgression', {
                        ref_id: e?.referenceID,
                        branch:e?.branch,
                        status:e?.status
                    })}>
                        <View style={[styles(themeObj).containerStyle, { height: layoutType == 'web' ? 145 : 127 }]} >
                            <Image source={{ uri: 'https://i.imgur.com/TBQvlll.jpeg' }} style={{ width: '25%', height: '100%', borderTopLeftRadius: 12, borderBottomLeftRadius: 12 }} />
                            <View style={{ width: '75%', padding:12 }}>
                                <ODSBadges restStyle={{ alignSelf: 'flex-end' }} type={StatusButton(e?.status)}>
                                   {StatusButton(e?.status)}
                                </ODSBadges>
                                <ODSText type='sm-bold' marginTop={8} color={colors?.text.primary}>{e?.propertyAddress}</ODSText>

                            </View>

                        </View></TouchableOpacity>)}
            </View>
        )
    }


    const renderPage1 = () => {
        return (
            <View style={{ flexDirection: 'column', top: -100, zIndex: 100 }}>
                <BoxShadow style={{ alignSelf: 'center' }} tWidth={'80%'} wWidth={'846px'} bottom={'10px'} right={'0px'}>
                    <>
                        <Image source={require('../../../openbrix-design-system/images/logos/fcc-logo.png')} style={{ width: 190, height: 60, resizeMode: 'contain', alignSelf: 'center', margin: 30 }} />
                        {properties.length>0 && <ODSText type='md' color={colors?.text.primary}>Please select which property you have been requested to complete the tenant application.</ODSText>}
                        {renderList()}

                    </>
                </BoxShadow>

            </View>
        );
    };



    return (
        <>
            <BackGroundGenerator props={props} isTLYF>
                <View style={{ minHeight: WIN_HEIGHT - 300 }}>
                    <View style={{ width: '100%' }}>
                        <ImageBackground
                            source={require('../../../openbrix-design-system/images/fcc/fcc-desktop.png')}
                            style={{ width: '100%', height: 300 }}
                            resizeMode={'cover'}
                        >
                            <ODSButton
                                rIcon={'TB-Arrow-Left'}
                                restStyle={{ marginTop: 20, marginLeft: 20, width: 200 }}
                                type="secondary"

                                disabled={false}
                                onPress={() => replaceAction(props?.navigation, 'FccHome')}
                            >
                                FCC Paragon
                            </ODSButton>
                        </ImageBackground>
                        {renderPage1()}
                    </View>
                </View>
            </BackGroundGenerator>

        </>
    );
};
const styles = (theme) =>
    StyleSheet.create({
        containerStyle: {
            borderRadius: 12,
            shadowColor: '#101828',
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
            backgroundColor: 'white',
            borderWidth: 1,
            borderColor: '#D0D6DD',
            flexDirection: 'row',
        },
    });

export default FccChooseAddress;
