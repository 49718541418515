import React, { useState, useEffect } from 'react';
import {
  Text,
  View,
  SafeAreaView,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { ODSIcon } from '../../../openbrix-design-system/ui/atoms/ODSIcon';
// import { deleteNotification, getNotifications } from 'reduxs/actions/notifications';
import { useTheme } from '@emotion/react';
import NotificationItem from '../../../openbrix-design-system/ui/molecules/Notifications/NotificationItem';
import { deleteNotification, getNotifications, markAllRead, markAsRead } from '../../redux/actions/notification';

const Notification = ({ onClose }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const LIMIT = 12;
  const { colors } = useTheme();
  const {notificationCount} =useSelector((state)=>state.notificationReducer);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);

    dispatch(
      getNotifications({ offset, limit: LIMIT }, (response) => {
        const { rows, count } = response;

        const newNotifications = rows.map((item) => ({
          id: item.notification_id,
          title: JSON.parse(item.context).title,
          content: JSON.parse(item.context).body,
          read: item.is_viewed ? 1 : 0,
          image: JSON.parse(item.context).image,
          navigateButton: JSON.parse(item.context).webRedirect,
          redirect: JSON.parse(item.context).webRedirect
        }));

        setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
        setHasMore(offset + notifications.length < count);
        setOffset(offset + 1);
        setIsLoading(false);
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      deleteNotification(id, () => {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification.id !== id)
        );
        onClose();
      })
    );
  };

  const handleMarkAllAsRead = () => {
    dispatch(
      markAllRead(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            read: 1,
          }))
        );
        onClose();
      })
    );
  };
  const handleMarkAsRead = (id, index) => {
    dispatch(
      markAsRead(id, () => {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === id ? { ...notification, read: 1 } : notification
          )
        );

      })
    );
  };

  const renderFooter = () => {
    if (!isLoading) return null;
    return (
      <Image
        source={require('../../../openbrix-design-system/images/loader.gif')}
        resizeMode="contain"
        style={styles.loader}
      />
    );
  };
  const renderHeader = (title, onPress) => (
    <View style={{ justifyContent: 'space-between', flexDirection: 'row', height: 80, alignItems: 'center', paddingHorizontal: 20, borderBottomWidth: 1, borderColor: colors.other['border-neutral'] }}>
      {notificationCount > 0 && <TouchableOpacity onPress={handleMarkAllAsRead} style={{ flexDirection: 'row' }}>
        <ODSIcon icon='TB-Check' size={20} color={colors.text.primary} />
        <ODSText type='md' marginLeft={12} color='#06909E' >Mark all as read</ODSText>
      </TouchableOpacity>}
      <TouchableOpacity onPress={onClose}>
      <ODSIcon icon='TB-Close' size={30}/>
      </TouchableOpacity>
    </View>
  );

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>

      <FlatList
        contentContainerStyle={styles.listContainer}
        data={notifications}
        ListEmptyComponent={() =>
          !isLoading && (
            <View style={styles.emptyContainer}>
              <ODSText type="h5" color={colors.text.primary}>
                No notifications yet
              </ODSText>
              <ODSText type="md" marginTop={8} color={colors.text.primary}>
                We'll let you know when updates arrive!
              </ODSText>
            </View>
          )
        }
        onEndReached={() => {
          if (hasMore) fetchNotifications();
        }}
        onEndReachedThreshold={0.5}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
        renderItem={({item})=> <NotificationItem
        title={item.title}
        content={item.content}
        subText={item.subText}
        read={item.read}
        image={item.image}
        icon={item.icon}
        webRedirect={item.redirect}
        file={item.file}
        onDelete={()=>{
            handleDelete(item.id)
        }}
        onPress={() => handleMarkAsRead(item.id)}
      />}
        keyExtractor={(item) => item.id.toString()}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    paddingBottom: 40,
    backgroundColor: 'white',
  },
  notificationItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginHorizontal: 16,
    marginBottom: 8,
    borderRadius: 8,
    elevation: 2,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
  },
  content: {
    flex: 1,
  },
  deleteButton: {
    marginLeft: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    height: 42,
    width: 42,
    alignSelf: 'center',
    marginVertical: 20,
  },
  emptyContainer: {
    flex: 1,
    paddingTop: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Notification;
