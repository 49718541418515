import idx from 'idx';
import { Appearance, View, useColorScheme } from 'react-native';
import OneSignal from 'react-onesignal';
import { bindActionCreators } from 'redux';
import { persistStore } from 'redux-persist';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import {  onMessage } from "firebase/messaging";
import * as RootNavigation from './RootNavigation';
import { store } from '../../src/redux/store/setup';
import Cookies from '../components/cookies/Cookies';
import getPlatformType from '../helpers/getPlatform';
import Customise from '../components/cookies/Customise';
import { addNotification, getNotificationsCount } from './../redux/actions/notification';
import CustomPopUp from './../commonComponents/customPopUp/customPopUp';
import StatusBarComponent from './../commonComponents/statusBarComponent';
import { LogoutApi, saveAuthTokenFromMobile } from '../../src/redux/actions/auth';
import { statusPopupAction, hideToasterPopUp } from '../redux/actions/statusPopup';
import Routes from './routes';
import { showCreditNativePayment,showInapNativeWebView } from '../redux/actions/mobileAction';
import ReactGA from "react-ga4";
import { messaging } from '../helpers/firebase';
import NotificationBar from '../commonComponents/NotificationBar';
function PreRoutes(props) {
  const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;

  const calledTimesRef = useRef(0);
  const layoutType = getPlatformType();
  const [rehydrated, setRehydrated] = useState(false);
  let { text, type, visible, popupVisible } = props.statusPopupReducer;
  const [cookiesPopupVisible, setCookiesPopupVisible] = useState(false);
  const show_cookies = useSelector((state) => state?.cookiesReducer?.show_banner_cookies);
  const show_customise = useSelector((state) => state?.cookiesReducer?.show_banner_customise);
  const authDataFromMobile =useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);
  const [showNotification,setNotification]=useState(null);
  useEffect(() => {
    console.log(getCurrentTheme,"tehm")

    const cookiesBanner = localStorage.getItem('cookies_status');
    onMessage(messaging, (payload) => {
      console.log("Message received. ", JSON.stringify(payload));
      props.getNotificationsCount();
      
      setNotification(payload)
      setTimeout(() => {
        setNotification(null)
      }, 10000);
      
    });
    if (cookiesBanner) {
      setCookiesPopupVisible(false);
    } else {
      setTimeout(() => {
        setCookiesPopupVisible(true);
      }, 4000);
    }
    ReactGA.initialize("G-B92QH6BYHD");
    
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        if (event?.data) {
          let data;
          console.log('event?.data', event);
          if (typeof event?.data === 'string') {
             data = JSON.parse(event?.data)
            let isMobile = data?.isMobile;
            let authData = data?.authData;
            
            console.log('Received message from webview:');
            console.log('isMobile:', isMobile);
            console.log('authData:', authData);
            console.log('calledTimesRef.current:', calledTimesRef.current);


            if (authData && calledTimesRef.current === 0) {
              props.saveAuthTokenFromMobile({authData,subUrl: data?.subUrl});
              calledTimesRef.current++;
              if(data?.isNativePaymentRequireForCreditBuilder){
                props.showCreditNativePayment(data.isNativePaymentRequireForCreditBuilder)
              }
        
              if(data?.isInAppWebViewImplemented){
              
                props.showInapNativeWebView(data.isInAppWebViewImplemented)
              }
              }
          }
        }
      } catch {}

    };
    // Add the event listener when the component mounts
    window.addEventListener('message', handleMessage);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        props.statusPopupAction();
      }, 4000);
    }
  }, [visible]);

  useEffect(() => {
    persistStore(store, {}, () => {
      setRehydrated(true);
    });
  }, []);

  useEffect(() => {
    if (props?.profile?.data?.user_id) {
      OneSignal.setExternalUserId(props?.profile?.data?.user_id)
        .then((r) => console.log(r))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [props?.profile]);

  return (
    <View style={{ flex: 1 }}>
      {rehydrated && <Routes />}
      {
        visible && <StatusBarComponent text={text} type={type} />
      }
      {showNotification && <NotificationBar text={showNotification.notification.title} body={showNotification.notification.body} redirect={showNotification.data?.webRedirect} type={"success"}/>}
      {authDataFromMobile ? null: cookiesPopupVisible && show_cookies && (
        <View
          style={{
            width: '100%',
            paddingTop: layoutType == 'phone' ? '18%' : '7%',
            position: 'absolute',
            height: '100%',
          }}
        >
          <Cookies
            setCookiesPopupVisible={setCookiesPopupVisible}
            cookiesPopupVisible={cookiesPopupVisible}
          />
        </View>
      )}
      {show_customise && (
        <View
          style={{
            width: '100%',
            marginTop: layoutType == 'phone' ? '18%' : '7%',
            position: 'absolute',
          }}
        >
          <Customise />
        </View>
      )}
      {/* {popupVisible && (
        <CustomPopUp
          loading={popupVisible}
          primaryButtonText={'Sign In?'}
          primaryText={'Not signed in!'}
          secondaryText={'Please login to view this page'}
          onPrimaryButtonPress={() => {
            RootNavigation.navigate('Sign in');
            props.LogoutApi();
            props.hideToasterPopUp();
          }}
          onSecondaryButtonPress={() => {
            props.hideToasterPopUp();
          }}
        />
      )} */}
    </View>
  );
}

const mapStateToProps = (state) => {
  const token = idx(state, (_) => _.appReducer.loginData.data.token);
  const { statusPopupReducer, notificationReducer } = state;
  const { profile } = state.authReducer;
  return { token, profile, statusPopupReducer, notificationReducer };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { statusPopupAction, hideToasterPopUp, LogoutApi, addNotification, saveAuthTokenFromMobile,showCreditNativePayment ,showInapNativeWebView,getNotificationsCount},
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PreRoutes);
