import React from 'react';
import styled from 'styled-components/native';
import { COLORS } from '../../constants/colorPallete';
import getPlatformType from '../../helpers/getPlatform';

const layoutType = getPlatformType();

let TabContainer, ChildrenView, BoxShadow, Title2, TitleBlack, Line, TextWrapper, Wrapper,IconButton;

const CardInputWrapper = styled.View`
  padding: 10px 20px;

`;
Title2 = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: ${(props) => (props.size ? props.size : '18px')};
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  text-decoration: ${(props) => (props.line ? 'underline' : 'none')};
  text-decoration-color: '#FFB580';
  text-align:justify;
`;
TitleBlack = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
`;
Line = styled.View`
  width: ${(props) => (props.width ? props.width : '151px')};
  height:${(props) => (props.height ? props.height : '1px')};
  border: 1px solid #999999;
  margin-top:2px
`;
IconButton = styled.TouchableOpacity`
width: 40px;
height: 40px;
border-radius:20px;
justify-content: center;
  align-items: center;
  background-color:${(props) => (props.color ? props.color : '#009688')};
`;
TextWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
if (layoutType == 'phone') {
  TabContainer = styled.View`
    width:100%;
    height:72px;
    flex-direction:row;
    background-color:${COLORS.GREEN};
    padding-vertical:12px;
    
  `;
  ChildrenView = styled.View`
    margin-horizontal: 20px;
  `;
  Wrapper=styled.View`
    width:100%;
    flex-direction: column;
    align-self: center
  `;
  BoxShadow = styled.View`
  background-color: #ffffff;
  width: ${layoutType=="phone"?'100%':'324px'};
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 19px 15px 32px 24px;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
  height: fit-content;
`;

} else if (layoutType == 'web') {
  Wrapper=styled.View`
  flex-direction: row;
    align-self: center;
  `;
  TabContainer = styled.View`
    width: 100%;
    height: 72px;
    flex-direction: row;
    background-color: ${COLORS.GREEN};
    padding-vertical: 12px;
    
  `;
  ChildrenView = styled.View`
    margin-horizontal: 60px;
    top: -100;
  `;
  BoxShadow = styled.View`
  background-color: #ffffff;
  width: 100%;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 19px 15px 32px 24px;
  margin-right: ${(props) => (props.right ? props.right : '0px')};
  height: fit-content;
`;
} else {
  TabContainer = styled.View`
    width:100%;
    height:72px;
    flex-direction:row;
    background-color:${COLORS.GREEN}
    padding-vertical:12px;

  `;
  ChildrenView = styled.View`
    margin-horizontal: 30px;
    top: -100;
  `;
  Wrapper=styled.View`
  flex-direction: row;
  align-self: center;
  `;
  BoxShadow = styled.View`
  background-color: #ffffff;
  width: ${(props) => (props.tWidth ? props.tWidth : '324px')};
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 19px 15px 32px 24px;
  margin-right: ${(props) => (props.right ? props.right : '0px')};
  height: fit-content;
`;
}

export { 
  CardInputWrapper,
  IconButton,
  TabContainer, ChildrenView, BoxShadow, Title2, TitleBlack, Line, TextWrapper ,Wrapper};
